import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@iconify/react';
import menu2Fill from '@iconify/icons-eva/menu-2-fill';
// material
import { alpha, styled } from '@mui/material/styles';
import { Box, Stack, AppBar, Toolbar, IconButton, Typography, useTheme } from '@mui/material';
// components
import { MHidden } from '../../components/@material-extend';
//
import Searchbar from './Searchbar';
import AccountPopover from './AccountPopover';
import LanguagePopover from './LanguagePopover';
import NotificationsPopover from './NotificationsPopover';
import ThemeChange from './ThemeChange';
import { ColorModeContext } from 'src/theme';
import Caller from './Caller';

// ----------------------------------------------------------------------

const DRAWER_WIDTH = 1;
const APPBAR_MOBILE = 64;
const APPBAR_DESKTOP = 52;

DashboardFooter.propTypes = {};

export default function DashboardFooter() {
  const { toggleColorMode, mode } = React.useContext(ColorModeContext);
  const theme = useTheme();
  const RootStyle = styled(AppBar)(({ theme }) => ({
    boxShadow: 'none',
    position: 'fixed',
    top: `calc(100% - ${45}px)`,
    backgroundColor: theme.palette.common.black,
    [theme.breakpoints.up('lg')]: {
      width: `calc(100% - ${DRAWER_WIDTH + 1}px)`
    },
  }));

  const ToolbarStyle = styled(Box)(({ theme }) => ({
    minHeight: APPBAR_MOBILE,
      padding: theme.spacing(1.5, 5, 0, 5)
  }));

  return (
    <RootStyle>
      <ToolbarStyle>
        <Stack direction="row" alignItems="center" spacing={{ xs: 0.5, sm: 1.5 }}>
          <Stack direction="row" alignItems="center" spacing={{ xs: 0.5, sm: 1.5 }}>
            <Typography
              sx={{ color: theme.palette.customBlackThemeColor.sidebarTextColor }}
              variant="subtitle2"
            >
              Account:
            </Typography>
            <Typography variant="subtitle2">uob</Typography>
          </Stack>
          &nbsp;
          <Stack direction="row" alignItems="center" spacing={{ xs: 0.5, sm: 1.5 }}>
            <Typography
              sx={{ color: theme.palette.customBlackThemeColor.sidebarTextColor }}
              variant="subtitle2"
            >
              Instance:
            </Typography>
                      <Typography variant="subtitle2">admissions</Typography>
          </Stack>
         
        </Stack>
      </ToolbarStyle>
    </RootStyle>
  );
}
