import { useContext } from 'react';
import { Icon } from '@iconify/react';
import bugFilled from '@iconify/icons-ant-design/bug-filled';
// material
import { alpha, styled } from '@mui/material/styles';
import { Box, Card, Divider, Typography, useTheme } from '@mui/material';
// utils
import { fShortenNumber } from '../../../utils/formatNumber';
import SimpleLoading from 'src/layouts/SImpleLoading/SimpleLoading';
import { ColorModeContext } from 'src/theme';
// ----------------------------------------------------------------------

const RootStyle = styled(Card)(({ theme, mode }) => ({
  boxShadow: 'none',
  padding: theme.spacing(2, 2),
  color: theme.palette.error.darker,
  backgroundColor: theme.palette.customBlackThemeColor.cardColor,
  height: 183
  // width: '100%'
}));

const ChildrenStyles = styled(Box)(({ theme }) => ({
  padding: theme.spacing(0, 0),
  display: 'flex',
  flexDirection: 'row',
  paddingTop: 2,
  alignItems: 'center',
  justifyContent: 'space-around',
  zIndex: 1,
  marginTop: '3rem'
}));

const Child2ItemsStyle = styled(Box)(({ theme }) => ({
  boxShadow: 'none',
  padding: theme.spacing(2, 1),
  display: 'flex',
  flexDirection: 'row',
  paddingTop: 1,
  alignItems: 'left',
  justifyContent: 'space-between',
  marginLeft: 2,
  // [theme.breakpoints.up('md')]: {
  //   width: 200
  // },
  // [theme.breakpoints.up('lg')]: {
  //   width: 300
  // },
  // [theme.breakpoints.up('lg')]: {
  //   width: 200
  // },

  height: 80,
  border: `1px solid ${alpha(theme.palette.customBlackThemeColor.sidebarTextColor, 0.7)}`
}));

const ContentWrapper = styled(Box)(({ theme }) => ({
  width: 150,
  height: 150,
  border: '1px solid #000'
}));

// ----------------------------------------------------------------------

const TOTAL = 10;

export default function ACDTalkTime(props) {
  const theme = useTheme();
  const { toggleColorMode, mode } = useContext(ColorModeContext);
  const { contactsHandled, totalHandledTime } = props;
  return (
    <RootStyle mode={mode}>
      <Typography
        variant="subtitle2"
        sx={{
          opacity: 0.72,
          fontSize: 22,
          // color: alpha(theme.palette.customBlackThemeColor.sidebarTextColor)
          color: theme.palette.common.black
        }}
      >
        ACD Talk Time
      </Typography>
      <ChildrenStyles>
        <Child2ItemsStyle>
          <Icon
            icon="akar-icons:clock"
            width={22}
            height={22}
            color={theme.palette.customBlackThemeColor.sidebarTextColor}
          />
          <Box sx={{ marginTop: 3, textAlign: 'right' }}>
            <Typography
              variant="subtitle2"
              sx={{
                opacity: 0.8,
                fontSize: 22,
                color: theme.palette.customBlackThemeColor.darkGray
              }}
            >
              {contactsHandled}
            </Typography>
            <Divider />
            <Typography
              variant="subtitle2"
              sx={{
                opacity: 0.8,
                fontSize: 16,
                color: theme.palette.customBlackThemeColor.sidebarTextColor
              }}
            >
              ACD Handler
            </Typography>
          </Box>
        </Child2ItemsStyle>
        <Child2ItemsStyle>
          <Icon
            icon="akar-icons:headphone"
            width={22}
            height={22}
            color={theme.palette.customBlackThemeColor.sidebarTextColor}
          />
          <Box sx={{ marginTop: 3, textAlign: 'right' }}>
            <Typography
              variant="subtitle2"
              sx={{
                opacity: 0.8,
                fontSize: 22,
                color: theme.palette.customBlackThemeColor.darkGray
              }}
            >
              {totalHandledTime.hour === '00'
                ? `${totalHandledTime.minutes}m ${totalHandledTime.seconds}s`
                : `${totalHandledTime.hour}h ${totalHandledTime.minutes}m ${totalHandledTime.seconds}s`}
            </Typography>
            <Divider />
            <Typography
              variant="subtitle2"
              sx={{
                opacity: 0.8,
                fontSize: 16,
                color: theme.palette.customBlackThemeColor.sidebarTextColor
              }}
            >
              ACD Time
            </Typography>
          </Box>
        </Child2ItemsStyle>
      </ChildrenStyles>
      {/* <Child2ItemsStyle>
        <Box sx={{ flexDirection: 'column', display: 'flex' }}>
          <Typography
            variant="subtitle2"
            sx={{
              opacity: 0.8,
              fontSize: 18,
              color: theme.palette.customBlackThemeColor.sidebarTextColor
            }}
          >
            Voice
          </Typography>
          <Typography
            variant="subtitle2"
            sx={{
              textAlign: 'center',
              opacity: 0.8,
              fontSize: 16,
              color: theme.palette.customBlackThemeColor.darkGray
            }}
          >
            0
          </Typography>
        </Box>
        <Box sx={{ flexDirection: 'column', display: 'flex' }}>
          <Typography
            variant="subtitle2"
            sx={{
              opacity: 0.8,
              fontSize: 18,
              color: theme.palette.customBlackThemeColor.sidebarTextColor
            }}
          >
            Callbacks
          </Typography>
          <Typography
            variant="subtitle2"
            sx={{
              textAlign: 'center',
              opacity: 0.8,
              fontSize: 16,
              color: theme.palette.customBlackThemeColor.darkGray
            }}
          >
            0
          </Typography>
        </Box>
      </Child2ItemsStyle>
      <Box sx={{ margin: '0px 20px', paddingTop: 1 }}>
        <Typography
          variant="subtitle2"
          sx={{
            opacity: 0.8,
            fontSize: 17,
            color: theme.palette.customBlackThemeColor.sidebarTextColor
          }}
        >
          Live Hook
        </Typography>
        <Divider />
      </Box>
      <Box sx={{ textAlign: 'center', alignItems: 'center', paddingTop: 2 }}>
        <Typography
          variant="subtitle2"
          sx={{
            opacity: 0.8,
            fontSize: 14,
            color: theme.palette.customBlackThemeColor.sidebarTextColor
          }}
        >
          Empty
        </Typography>
      </Box> */}
    </RootStyle>
  );
}
