import { Icon } from '@iconify/react';
import bugFilled from '@iconify/icons-ant-design/bug-filled';
// material
import { alpha, styled } from '@mui/material/styles';
import { Box, Card, Divider, Typography, useTheme } from '@mui/material';
// utils
import { fShortenNumber } from '../../../utils/formatNumber';
import SimpleLoading from 'src/layouts/SImpleLoading/SimpleLoading';

// ----------------------------------------------------------------------

const RootStyle = styled(Card)(({ theme }) => ({
  boxShadow: 'none',
  // textAlign: 'center',
  padding: theme.spacing(2, 2),
  color: theme.palette.error.darker,
  backgroundColor: theme.palette.customBlackThemeColor.cardColor,
  height: '100%',
  width: '100%'
}));

const ChildrenStyles = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2, 2),
  display: 'flex',
  flexDirection: 'row',
  paddingTop: 15,
  alignItems: 'center',
  justifyContent: 'space-around',
  zIndex: 1
}));

const Child2ItemsStyle = styled(Box)(({ theme }) => ({
  boxShadow: 'none',
  padding: theme.spacing(2, 3),
  display: 'flex',
  flexDirection: 'row',
  paddingTop: 10,
  alignItems: 'center',
  justifyContent: 'space-between'
}));

// ----------------------------------------------------------------------

const TOTAL = 10;

export default function ContactsInQueue({ contactsInQueue, voice }) {
  const theme = useTheme();
  return (
    <RootStyle>
      <Typography
        variant="subtitle2"
        sx={{
          opacity: 0.72,
          fontSize: 22,
          color: theme.palette.common.black
          // color: alpha(theme.palette.customBlackThemeColor.sidebarTextColor)
        }}
      >
        Contacts In Queue
      </Typography>
      <ChildrenStyles>
        <Box sx={{ width: 400, backgroundColor: theme.palette.grey[300], opacity: 0.7 }}>
          <Typography
            variant="subtitle2"
            sx={{
              fontSize: 22,
              textAlign: 'center',
              color: theme.palette.customBlackThemeColor.sidebarTextColor
            }}
          >
            {contactsInQueue}
          </Typography>
        </Box>
      </ChildrenStyles>
      <Child2ItemsStyle>
        <Box sx={{ flexDirection: 'column', display: 'flex' }}>
          <Typography
            variant="subtitle2"
            sx={{
              opacity: 0.8,
              fontSize: 18,
              color: theme.palette.customBlackThemeColor.sidebarTextColor
            }}
          >
            Voice
          </Typography>
          <Typography
            variant="subtitle2"
            sx={{
              textAlign: 'center',
              opacity: 0.8,
              fontSize: 16,
              color: theme.palette.customBlackThemeColor.darkGray
            }}
          >
            {voice}
          </Typography>
        </Box>
        <Box sx={{ flexDirection: 'column', display: 'flex' }}>
          <Typography
            variant="subtitle2"
            sx={{
              opacity: 0.8,
              fontSize: 18,
              color: theme.palette.customBlackThemeColor.sidebarTextColor
            }}
          >
            Callbacks
          </Typography>
          <Typography
            variant="subtitle2"
            sx={{
              textAlign: 'center',
              opacity: 0.8,
              fontSize: 16,
              color: theme.palette.customBlackThemeColor.darkGray
            }}
          >
            0
          </Typography>
        </Box>
      </Child2ItemsStyle>
      <Box sx={{ margin: '0px 20px', paddingTop: 1 }}>
        <Typography
          variant="subtitle2"
          sx={{
            opacity: 0.8,
            fontSize: 17,
            color: theme.palette.customBlackThemeColor.sidebarTextColor
          }}
        >
          Live Hook
        </Typography>
        <Divider />
      </Box>
      <Box sx={{ textAlign: 'center', alignItems: 'center', paddingTop: 2 }}>
        <Typography
          variant="subtitle2"
          sx={{
            opacity: 0.8,
            fontSize: 14,
            color: theme.palette.customBlackThemeColor.sidebarTextColor
          }}
        >
          Empty
        </Typography>
      </Box>
    </RootStyle>
  );
}
