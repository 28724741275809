import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import LogoOnlyLayout from './layouts/LogoOnlyLayout';
//
import Login from './pages/Login';
import Register from './pages/Register';
import DashboardApp from './pages/DashboardApp';
import AgentApp from './pages/AgentApp';
import QueueMetricsApp from './pages/QueueMetricsApp';
import Contact from './pages/Contact';
import Blog from './pages/Blog';
import User from './pages/User';
import NotFound from './pages/Page404';
import PhoneComponent from './pages/Phone';
// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    {
      path: '/dashboard',
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/dashboard/phone" replace /> },
        
      { path: 'phone', element: <PhoneComponent /> },
          { path: 'app', element: <DashboardApp /> },
        { path: 'contact', element: <Contact /> },
          { path: 'agents', element: <AgentApp /> },
         { path: 'queuemetrics', element: <QueueMetricsApp /> }
      ]
    },
    {
      path: '/',
      element: <LogoOnlyLayout />,
      children: [
        { path: 'login', element: <Login /> },
        { path: 'register', element: <Register /> },
        { path: '404', element: <NotFound /> },
        { path: '/', element: <Navigate to="/dashboard" /> },
        { path: '*', element: <Navigate to="/404" /> }
      ]
    },
    { path: '*', element: <Navigate to="/404" replace /> }
  ]);
}
