import actionTypes from '../actions/actionTypes';
import _ from 'lodash';
const initialValues = {
  data: []
};

const RecentlyActivity = (state = initialValues, action) => {
  switch (action.type) {
    case actionTypes.ADD_RECENTLY_ACTIVITY:
      return {
        data: _.uniqBy([...state.data, action.payload], 'contactId')
      };
    case actionTypes.RESET_ACTIVITY:
      return {
        data: []
      };
    default:
      return initialValues;
  }
};

export default RecentlyActivity;
