import React from 'react';
import { alpha, styled, useTheme } from '@mui/material/styles';
import { Card, Typography, Box, Grid } from '@mui/material';
import { Icon } from '@iconify/react';

const RootStyle = styled(Card)(({ theme }) => ({
  boxShadow: 'none',
  // textAlign: 'center',
  padding: theme.spacing(1, 2),
  color: theme.palette.common.black,
  backgroundColor: theme.palette.customBlackThemeColor.cardColor,
  height: 140,
  width: '100%'
}));

const ChildrenStyles = styled(Box)(({ theme }) => ({
  padding: theme.spacing(0, 0),
  marginTop: '.5rem'
}));
const BoxContainer = styled(Box)(({ theme }) => ({
  border: `1px solid ${theme.palette.grey[400]}`,
  padding: '10px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  '& .item': {
    textAlign: 'center'
  },
  '& .item-left': {
    alignSelf: 'flex-start',
    textAlign: 'left'
  }
}));
const HandlingTime = (props) => {
  const theme = useTheme();
  const { averageHandleTime } = props;
  return (
    <RootStyle>
      <Typography
        variant="subtitle2"
        sx={{
          opacity: 0.72,
          fontSize: 22,
          color: alpha(theme.palette.customBlackThemeColor.sidebarTextColor)
        }}
      >
        Handling Time
      </Typography>
      <ChildrenStyles>
        <BoxContainer>
          <span className="item item-left">Average</span>
          <span className="item">
            {averageHandleTime.hour === '00'
              ? `${averageHandleTime.minutes}m : ${averageHandleTime.seconds}s`
              : `${averageHandleTime.hour}h : ${averageHandleTime.minutes}m ${averageHandleTime.seconds}s`}
          </span>
        </BoxContainer>
      </ChildrenStyles>
    </RootStyle>
  );
};

export default HandlingTime;
