import { Icon } from '@iconify/react';
import pieChart2Fill from '@iconify/icons-eva/pie-chart-2-fill';
import logOutFill from '@iconify/icons-eva/log-out-fill';
import peopleFill from '@iconify/icons-eva/people-fill';
import shoppingBagFill from '@iconify/icons-eva/shopping-bag-fill';
import phoneFilled from '@iconify/icons-ant-design/phone-filled';
import settingFilled from '@iconify/icons-ant-design/setting-filled';
import homeFilled from '@iconify/icons-ant-design/home-filled';
import fileTextFill from '@iconify/icons-eva/file-text-fill';
import lockFill from '@iconify/icons-eva/lock-fill';
import personAddFill from '@iconify/icons-eva/person-add-fill';
import alertTriangleFill from '@iconify/icons-eva/alert-triangle-fill';
import refreshFill from '@iconify/icons-eva/refresh-fill';
// ----------------------------------------------------------------------

const getIcon = (name) => <Icon icon={name} width={22} height={22} />;

export const sidebarConfig = [
    {
        title: 'Phone',
        path: '/dashboard/phone',
        icon: getIcon(phoneFilled)
    },
  //  {
  //  title: 'Activity',
  //  path: '/dashboard/app',
  //      icon: getIcon(fileTextFill)
  //},
  //{
  //  title: 'Contact',
  //  path: '/dashboard/Contact',
  //  icon: getIcon('ic:baseline-space-dashboard')
  //},
  
  //{
  //  title: 'Agent',
  //  path: '/dashboard/agents',
  //    icon: getIcon(peopleFill)
  //  },
  //  {
  //      title: 'Queue',
  //      path: '/dashboard/queuemetrics',
  //      icon: getIcon('ic:baseline-space-dashboard')
  //  }

  // {
  //   title: 'blog',
  //   path: '/dashboard/blog',
  //   icon: getIcon(fileTextFill)
  // },
  // {
  //   title: 'login',
  //   path: '/login',
  //   icon: getIcon(lockFill)
  // },
  // {
  //   title: 'register',
  //   path: '/register',
  //   icon: getIcon(personAddFill)
  // },
];

export const sidebarConfigBottom = [
  // {
  //   title: 'Logout',
  //   path: '/dashboard/app',
  //   icon: getIcon(pieChart2Fill)
  // },
  // {
  //   title: 'user',
  //   path: '/dashboard/user',
  //   icon: getIcon(peopleFill)
  // },
  {
    title: 'Refresh',
    path: '/dashboard/user',
    icon: getIcon(refreshFill)
  }
];
