const historicalValues = [
    {
        Name: 'AFTER_CONTACT_WORK_TIME',
        Unit: 'SECONDS',
        Statistic: 'AVG'
    },
    {
        Name: 'CONTACTS_QUEUED',
        Unit: 'COUNT',
        Statistic: 'SUM'
    },
    {
        Name: 'CONTACTS_ABANDONED',
        Unit: 'COUNT',
        Statistic: 'SUM'
    },
    {
        Name: 'CONTACTS_HANDLED',
        Unit: 'COUNT',
        Statistic: 'SUM'
    },
    {
        Name: 'CONTACTS_HANDLED_INCOMING',
        Unit: 'COUNT',
        Statistic: 'SUM'
    },
    {
        Name: 'CONTACTS_HANDLED_OUTBOUND',
        Unit: 'COUNT',
        Statistic: 'SUM'
    },
    {
        Name: 'CALLBACK_CONTACTS_HANDLED',
        Unit: 'COUNT',
        Statistic: 'SUM'
    },
    {
        Name: 'HANDLE_TIME',
        Unit: 'SECONDS',
        Statistic: 'AVG'
    },
    {
        Name: 'QUEUE_ANSWER_TIME',
        Unit: 'SECONDS',
        Statistic: 'AVG'
    },
    {
        Name: 'INTERACTION_TIME',
        Unit: 'SECONDS',
        Statistic: 'AVG'
    },
    {
        Name: 'CONTACTS_TRANSFERRED_OUT',
        Unit: 'COUNT',
        Statistic: 'SUM'
    },
    {
        Name: 'CONTACTS_MISSED',
        Unit: 'COUNT',
        Statistic: 'SUM'
    },
    {
        Name: 'OCCUPANCY',
        Unit: 'PERCENT',
        Statistic: 'AVG'
    },
    {
        Name: 'QUEUED_TIME',
        Unit: 'SECONDS',
        Statistic: 'MAX'
    },
    {
        Name: 'HOLD_TIME',
        Unit: 'SECONDS',
        Statistic: 'AVG'
    },
    {
        Name: 'SERVICE_LEVEL',
        Threshold: {
            Comparison: 'LT',
            ThresholdValue: 120.0
        },
        Unit: 'PERCENT',
        Statistic: 'AVG'
    },
    {
        Name: 'SERVICE_LEVEL',
        Threshold: {
            Comparison: 'LT',
            ThresholdValue: 500.0
        },
        Unit: 'PERCENT',
        Statistic: 'AVG'
    }
];
const currentMetrics = [
    {
        Name: 'AGENTS_ON_CALL',
        Unit: 'COUNT'
    },
    {
        Name: 'AGENTS_ONLINE',
        Unit: 'COUNT'
    },
    {
        Name: 'AGENTS_AVAILABLE',
        Unit: 'COUNT'
    },
    {
        Name: 'AGENTS_NON_PRODUCTIVE',
        Unit: 'COUNT'
    },
    {
        Name: 'AGENTS_AFTER_CONTACT_WORK',
        Unit: 'COUNT'
    },
    {
        Name: 'AGENTS_ERROR',
        Unit: 'COUNT'
    },
    {
        Name: 'AGENTS_STAFFED',
        Unit: 'COUNT'
    },
    {
        Name: 'CONTACTS_IN_QUEUE',
        Unit: 'COUNT'
    },
    {
        Name: 'OLDEST_CONTACT_AGE',
        Unit: 'SECONDS'
    },
    {
        Name: 'CONTACTS_SCHEDULED',
        Unit: 'COUNT'
    },
    {
        Name: 'AGENTS_ON_CONTACT',
        Unit: 'COUNT'
    },
    {
        Name: 'SLOTS_ACTIVE',
        Unit: 'COUNT'
    },
    {
        Name: 'SLOTS_AVAILABLE',
        Unit: 'COUNT'
    }
];

export { historicalValues, currentMetrics };
