import { useContext, useEffect, useState, useMemo, useCallback, useLayoutEffect } from 'react';
import { Icon } from '@iconify/react';
import bugFilled from '@iconify/icons-ant-design/bug-filled';
// material
import { alpha, styled } from '@mui/material/styles';
import {
  Box,
  Card,
  Divider,
  Typography,
  useTheme,
  Checkbox,
  FormGroup,
  FormControlLabel
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import Backend, { backendURL } from '../../../api/backend';
import { fShortenNumber } from '../../../utils/formatNumber';
import SimpleLoading from 'src/layouts/SImpleLoading/SimpleLoading';
import { ColorModeContext } from 'src/theme';
import actionTypes from '../../../redux/actions/actionTypes';
import axios from 'axios';
// ----------------------------------------------------------------------

const RootStyle = styled(Card)(({ theme, mode }) => ({
  boxShadow: 'none',
  padding: theme.spacing(2, 2),
  color: theme.palette.error.darker,
  backgroundColor: theme.palette.customBlackThemeColor.cardColor,
  height: 90,
  width: '100%'
}));

const ChildrenStyles = styled(Box)(({ theme }) => ({
  padding: theme.spacing(0, 0),
  display: 'flex',
  flexDirection: 'row',
  paddingTop: 5,
  zIndex: 1,
  width: '100%',
  height: '100%',
  padding: '1rem 0rem',
  '.MuiFormControlLabel-label': {
    color: theme.palette.common.black
  }
}));

const Child2ItemsStyle = styled(Box)(({ theme }) => ({
  boxShadow: 'none',
  padding: theme.spacing(2, 1),
  display: 'flex',
  flexDirection: 'row',
  paddingTop: 1,
  alignItems: 'left',
  justifyContent: 'space-between',
  marginLeft: 2,
  [theme.breakpoints.up('md')]: {
    width: 200
  },
  [theme.breakpoints.up('lg')]: {
    width: 300
  },
  [theme.breakpoints.up('lg')]: {
    width: 200
  },

  height: 100,
  border: `1px solid ${alpha(theme.palette.customBlackThemeColor.sidebarTextColor, 0.7)}`
}));
const WrapCodeContainer = styled('div')(({ theme }) => ({
  overflowX: 'hidden',
  overflowY:'scroll',
  height: '100%',
  width: '100%',
  '& > :not(:first-child)': {
    marginTop: '.5rem'
  }
}));
const ContentWrapper = styled(Box)(({ theme }) => ({
  width: 150,
  height: 150,
  border: '1px solid #000'
}));
// ----------------------------------------------------------------------

const TOTAL = 10;
const WrapItem = styled('div')(({ theme, active }) => ({
  width: '100%',
  border: active
    ? `1px solid ${theme.palette.chart.blue[0]}`
    : `1px solid ${theme.palette.grey[200]}`,
  padding: '0.4rem',
  //borderRadius: '10px',
  cursor: 'pointer',
  color: theme.palette.common.black,
  backgroundColor: theme.palette.grey[200],
  fontSize: '0.8rem'
}));
export default function WrapUpCode(props) {
  const theme = useTheme();
  const { toggleColorMode, mode } = useContext(ColorModeContext);
  const selectedWrapCodes = useSelector((state) => state.WrapCodes.data);
  const [wrapCodesList, setWrapCodesList] = useState([]);
  const { contactsHandled, totalHandledTime } = props;
  const dispatch = useDispatch();
  const getWrapCodes = async () => {
    try {
      const data = await Backend.get(backendURL.getWrapCode);
      const response = data?.data?.body?.Item?.wrapUpCodes || [];

      setWrapCodesList([...response]);
    } catch (error) {
      console.log('err getting in wrap code');
      console.log(error);
    }
  };
  useEffect(() => {
    getWrapCodes();
  }, []);
  //   console.log(`selected wrap codes === `);
  //   console.log(selectedWrapCodes);
  return (
    <RootStyle mode={mode}>
      <Typography
        variant="subtitle2"
        sx={{
          opacity: 0.72,
          fontSize: 14,
          color: theme.palette.common.black
        }}
      >
        Wrap Up Code
      </Typography>
      <ChildrenStyles>
        <WrapCodeContainer>
          {wrapCodesList?.map(
            (item) => {
              return (
                <WrapItem
                  key={item}
                  active={selectedWrapCodes.includes(item) ? true : false}
                  onClick={() => {
                    if (!selectedWrapCodes.includes(item)) {
                      dispatch({
                        type: actionTypes.ADD_WRAP_CODE,
                        payload: `${item}`
                      });
                    } else {
                      dispatch({
                        type: actionTypes.REMOVE_WRAP_CODE,
                        payload: `${item}`
                      });
                    }
                  }}
                >
                  {item}
                </WrapItem>
              );
            }
            // <FormControlLabel
            //   control={<Checkbox />}
            //   label={item}
            //   key={item}
            //   onChange={(e) => {
            // if (e.target.checked) {
            //   dispatch({
            //     type: actionTypes.ADD_WRAP_CODE,
            //     payload: `${item}`
            //   });
            // } else {
            //   dispatch({
            //     type: actionTypes.REMOVE_WRAP_CODE,
            //     payload: `${item}`
            //   });
            // }
            //   }}
            // />
          )}
        </WrapCodeContainer>
      </ChildrenStyles>
    </RootStyle>
  );
}
