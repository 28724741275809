import React from 'react';
import { useEffect, useState } from 'react';
// material
import { Container, Stack, Typography, Box, Grid } from '@mui/material';
// components
import Page from '../components/Page';
//
import { Contacts, ContactsInQueue, WaitTime } from 'src/components/_dashboard/app';
import { ColorModeContext } from 'src/theme';
import { awsConnect, awsConnectInstanceId } from 'src/api/awsConnect';
import { currentMetrics } from 'src/api/HistoricalMetrics';

// ----------------------------------------------------------------------

export default function Contact() {
  const [contactInQueue, setContactInQueue] = useState(0);
  const [agentOnCall, setAgentOnCall] = useState(0);
  const [metricsData, setMetricsData] = React.useState(null);
  const [agentAvailable, setAgentAvailable] = useState(0);
  const [agentOnline, setAgentOnline] = useState(0);
  const { toggleColorMode, mode } = React.useContext(ColorModeContext);

  useEffect(() => {
    // Get Current Metric Data
    let currentMetricsInterval = setInterval(() => {
      awsConnect.getCurrentMetricData(
        {
          InstanceId: awsConnectInstanceId,
          CurrentMetrics: currentMetrics,
          Filters: {
            Channels: ['VOICE'], //[('CHAT', 'TASK')],
            Queues: [
              'arn:aws:connect:eu-west-2:355721060766:instance/5729b379-f5fe-4017-aac1-15ba8ed5e9b6/queue/d09c4cb0-208c-4df2-9d45-9927eec3df42'
              // 'arn:aws:connect:us-east-1:767282783672:instance/9c763e34-8f80-4824-9b7c-9857f7a0dc07/queue/5f6cbce6-5167-4f4a-a26e-971f860bcb20'
              // 'arn:aws:connect:us-east-1:767282783672:instance/9c763e34-8f80-4824-9b7c-9857f7a0dc07/queue/7f1c0d40-7a2b-409c-8fa8-833714dbc1ef'
              // 'arn:aws:connect:us-east-1:767282783672:instance/9c763e34-8f80-4824-9b7c-9857f7a0dc07/queue/962ae2bb-14f8-4f79-adf4-45fee8f513cb'
            ]
          },
          Groupings: ['QUEUE', 'CHANNEL']
          // MaxResults: 20
        },
        (err, data) => {
          if (data) {
            setMetricsData(data);
            if (data.MetricResults.length > 0) {
              data.MetricResults.map((item) => {
                item.Collections.map((colItem) => {
                  if (colItem.Metric.Name === 'CONTACTS_IN_QUEUE') {
                    setContactInQueue(colItem.Value);
                  } else if (colItem.Metric.Name === 'AGENTS_AVAILABLE') {
                    setAgentAvailable(colItem.Value);
                  } else if (colItem.Metric.Name === 'AGENTS_ONLINE') {
                    setAgentOnline(colItem.Value);
                  } else if (colItem.Metric.Name === 'AGENTS_ON_CALL') {
                    setAgentOnCall(colItem.Value);
                  }
                });
              });
            }
          } else if (err) {
            console.log('Error found in getting data of aws connect');
            console.log(err);
            console.log(err.stack);
          }
        }
      );
    }, 3000);
    return () => {
      clearInterval(currentMetricsInterval);
    };
  }, []);

  return (
    <Page title="Contacts Data: Contacts">
      <Container>
        <Box sx={{ pb: 5 }}>
          <Typography variant="h3" color={mode === 'light' ? '#000' : '#fff'}>
            Contact Information
          </Typography>
        </Box>

        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={4} lg={4}>
            <ContactsInQueue contactsInQueue={contactInQueue} voice={agentOnCall} />
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4}>
            <WaitTime />
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4}>
            <Contacts contactInQueue={contactInQueue} />
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}
