import actionTypes from '../actions/actionTypes';
import _ from 'lodash';
const initialValues = {
  data: []
};

const WrapCodes = (state = initialValues, action) => {
  switch (action.type) {
    case actionTypes.ADD_WRAP_CODE:
      return {
        data: Array.from(new Set([...state.data, action.payload]))
      };
    case actionTypes.REMOVE_WRAP_CODE:
      return {
        data: _.remove([...state.data], function (item) {
          return item !== action.payload;
        })
      };
    default:
      return initialValues;
  }
};

export default WrapCodes;
