import React, { useState, Component } from 'react';
import { Box, Grid, Container, Typography, Button } from '@mui/material';
import Dailer from 'src/components/Dailer';
import Page from '../components/Page';
import {
    RecentActivity, AgentAvailability, AgentPerformance, AgentStatus, QueueMetricsAvailability, WaitTime, WrapUpCodeSelect
} from '../components/_dashboard/app';
import { styled } from '@mui/material';

const Root = styled('div')(() => ({
    height: '100%',
    display: 'flex',
    justifyContent: 'center'
}));

const Phone = () => {
    const [role, setRole] = useState('')
    const [contactid, setContactId] = useState('')
    const [agentname, setAgentName] = useState('')
    return (
        <Page>

            <Container maxWidth="xl">

                <Grid container spacing={2}>
                    <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
                        <Grid item>
                            <WrapUpCodeSelect setRole={setRole} contactid={contactid} />
                        </Grid>
                        <Grid item height={520} >
                            <Dailer role={role} setAgentName={setAgentName} setContactId={setContactId} />
                        </Grid>
                    </Grid>
                   
                    <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                        <Grid item>
                            <AgentPerformance agentname={agentname} />
                        </Grid>
                        <Grid item marginTop={1}>
                            <RecentActivity  agentname={agentname} />
                            </Grid>
                    </Grid>
                    <Grid item xs={5} sm={5} md={5} lg={5}>
                        
                            <AgentStatus />
                          
                        <Grid item marginTop={1}>
                            <WaitTime />
                            </Grid>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12}>

                        <QueueMetricsAvailability />
                        </Grid>
                   
                </Grid>
            </Container>

        </Page>
    );
};

export default Phone;
