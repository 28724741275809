import React, { useState, useEffect } from 'react';
import { alpha, styled, useTheme } from '@mui/material/styles';
import { Card, Typography, Box, Grid } from '@mui/material';
import { Icon } from '@iconify/react';
import { awsConnect, awsConnectInstanceId } from '../../../api/awsConnect';
import { currentMetrics, historicalValues } from 'src/api/HistoricalMetrics';

const RootStyle = styled(Card)(({ theme }) => ({
    boxShadow: 'none',
    // textAlign: 'center',
    padding: theme.spacing(1, 2),
    color: theme.palette.common.black,
    backgroundColor: theme.palette.customBlackThemeColor.cardColor,
    height: '100%',
    width: '100%'
}));

const ChildrenStyles = styled(Box)(({ theme }) => ({
    padding: theme.spacing(0, 0),
    marginTop: '.3rem'
}));
const BoxContainer = styled(Box)(({ theme }) => ({
    border: `1px solid ${theme.palette.grey[400]}`,
    padding: '5px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    '& .item': {
        textAlign: 'center',
        fontSize: '12px'
    },
    '& .item-left': {
        alignSelf: 'flex-start',
        textAlign: 'left'
    }
}));

const NoData = styled('p')(({ theme }) => ({
    textAlign: 'center',
    marginTop: '1rem',
    width: '100%',
    height: '225px',
    color: theme.palette.grey['500']
}));
const WaitTime = (props) => {
    const theme = useTheme();

    const [contactsHandled, setcontactsHandled] = useState(0);
    const [contactsHandledIncoming, setcontactsHandledIncoming] = useState(0);
    const [contactsHandledOutbound, setcontactsHandledOutbound] = useState(0);
    const [contactsHandledCallback, setcontactsHandledCallback] = useState(0);
    const [contactsAbondoned, setcontactsAbondoned] = useState(0);
    const [contactsMissed, setcontactsMissed] = useState(0);
    const [interactionTime, setinteractionTime] = useState(0);
    const [handleTime, sethandleTime] = useState(0);
    const [queueAnswerTime, setqueueAnswerTime] = useState(0);
    const [serviceLevel, setserviceLevel] = useState(0);
    const [serviceLevel500, setserviceLevel500] = useState(0);
    const [metricData, setmetricData] = useState([]);

    useEffect(() => {
        let currentMetricsInterval = setInterval(() => {
        const myDate = new Date().toLocaleString('en-US', { timeZone: 'Europe/London' });
        const startDate = new Date(myDate);
        startDate.setHours(0);
        startDate.setMinutes(0);
        startDate.setSeconds(0);
        let startTime = startDate.getTime();
        var endDate = new Date(myDate);
        var endTimeCalc = endDate.getMinutes() - endDate.getMinutes() % 5;
        endDate.setMinutes(endTimeCalc);
        endDate.setSeconds(0);
        let endTime = endDate.getTime();
     
            awsConnect.getMetricData(
                {
                    InstanceId: awsConnectInstanceId,
                    StartTime: startDate,
                    EndTime: endDate,
                    Filters: {
                        Channels: ['VOICE'], //[('CHAT', 'TASK')],
                        Queues: [
                            "arn:aws:connect:eu-west-2:767282783672:instance/92a48059-cba0-48be-ae25-3f56b629e327/queue/5fccf498-53bf-4160-9858-093963b36aaf"
                        ]
                    },
                    Groupings: ['QUEUE'],
                    HistoricalMetrics: historicalValues,
                    MaxResults: 20
                },
                (err, data) => {
                    if (data) {
                        setmetricData(data.MetricResults);
                        if (data.MetricResults.length >= 1) {
                            data.MetricResults.map((item) => {
                                item.Collections.map((colItem) => {
                                    if (colItem.Metric.Name === 'CONTACTS_HANDLED') {
                                        setcontactsHandled(colItem.Value);
                                    } else if (colItem.Metric.Name === 'CONTACTS_HANDLED_INCOMING') {
                                        setcontactsHandledIncoming(colItem.Value);
                                    }
                                    else if (colItem.Metric.Name === 'CONTACTS_HANDLED_OUTBOUND') {
                                        setcontactsHandledOutbound(colItem.Value);
                                    }
                                    else if (colItem.Metric.Name === 'CALLBACK_CONTACTS_HANDLED') {
                                        setcontactsHandledCallback(colItem.Value);
                                    }
                                    else if (colItem.Metric.Name === 'CONTACTS_ABANDONED') {
                                        setcontactsAbondoned(colItem.Value);
                                    }
                                    else if (colItem.Metric.Name === 'CONTACTS_MISSED') {
                                        setcontactsMissed(colItem.Value);
                                    }
                                    else if (colItem.Metric.Name === 'INTERACTION_TIME') {
                                        setinteractionTime(colItem.Value);
                                    }
                                    else if (colItem.Metric.Name === 'HANDLE_TIME') {
                                        sethandleTime(colItem.Value);
                                    }
                                    else if (colItem.Metric.Name === 'QUEUE_ANSWER_TIME') {
                                        setqueueAnswerTime(colItem.Value);
                                    }
                                    else if (colItem.Metric.Name === 'SERVICE_LEVEL') {
                                        if (colItem.Metric.Threshold.ThresholdValue == 120)
                                            setserviceLevel(colItem.Value);
                                        else
                                            setserviceLevel500(colItem.Value);
                                    }
                                });
                            });
                        }
                    }
                    else if (err) {
                        console.log('Error found in getting data of aws connect');
                        console.log(err);
                        console.log(err.stack);
                        setcontactsHandled(0);
                        setcontactsHandledIncoming(0);
                        setcontactsHandledOutbound(0);
                        setcontactsHandledCallback(0);
                        setcontactsAbondoned(0);
                        setcontactsMissed(0);
                        setinteractionTime(0);
                        sethandleTime(0);
                        setqueueAnswerTime(0);
                        setserviceLevel(0);
                        setserviceLevel500(0);
                    }
                    else {
                        setcontactsHandled(0);
                        setcontactsHandledIncoming(0);
                        setcontactsHandledOutbound(0);
                        setcontactsHandledCallback(0);
                        setcontactsAbondoned(0);
                        setcontactsMissed(0);
                        setinteractionTime(0);
                        sethandleTime(0);
                        setqueueAnswerTime(0);
                        setserviceLevel(0);
                        setserviceLevel500(0);
                    }
            
        });
        }, 5000);
        return () => {
            clearInterval(currentMetricsInterval);
        };

    }, []);
    return (
        <RootStyle>
            <Typography
                variant="subtitle2"
                sx={{
                    opacity: 0.72,
                    fontSize: 16,
                    color: alpha(theme.palette.customBlackThemeColor.sidebarTextColor)
                }}
            >
                Team Performance
      </Typography>
            <ChildrenStyles>
                {metricData?.length > 0 ? (
                    <Grid container>
                        <Grid item ls={6} md={6} sm={6} xs={6} xl={6}>
                            <BoxContainer>
                                <span className="item item-left">Handled</span>
                                <span className="item">{contactsHandled}</span>
                            </BoxContainer>
                        </Grid>
                        <Grid item ls={6} md={6} sm={6} xs={6} xl={6}>
                            <BoxContainer style={{ borderLeft: 'none' }}>
                                <span className="item item-left">Inbound</span>
                                <span className="item">{contactsHandledIncoming}</span>
                            </BoxContainer>
                        </Grid>
                        <Grid item ls={6} md={6} sm={6} xs={6} xl={6}>
                            <BoxContainer>
                                <span className="item item-left">Outbound</span>
                                <span className="item">{contactsHandledOutbound}</span>
                            </BoxContainer>
                        </Grid>
                        <Grid item ls={6} md={6} sm={6} xs={6} xl={6}>
                            <BoxContainer style={{ borderLeft: 'none' }}>
                                <span className="item item-left">Queue Answer Time</span>
                                <span className="item">{queueAnswerTime}s</span>
                            </BoxContainer>
                        </Grid>
                        <Grid item ls={6} md={6} sm={6} xs={6} xl={6}>
                            <BoxContainer>
                                <span className="item item-left">Abondoned Calls</span>
                                <span className="item">{contactsAbondoned}</span>
                            </BoxContainer>
                        </Grid>
                        <Grid item ls={6} md={6} sm={6} xs={6} xl={6}>
                            <BoxContainer>
                                <span className="item item-left">Missed</span>
                                <span className="item">{contactsMissed}</span>
                            </BoxContainer>
                        </Grid>

                        <Grid item ls={6} md={6} sm={6} xs={6} xl={6}>
                            <BoxContainer >
                                <span className="item item-left">Average Talk Time</span>
                                <span className="item">{interactionTime}s</span>
                            </BoxContainer>
                        </Grid>
                        <Grid item ls={6} md={6} sm={6} xs={6} xl={6}>
                            <BoxContainer>
                                <span className="item item-left">Avg Handle Time</span>
                                <span className="item">{handleTime}s</span>
                            </BoxContainer>
                        </Grid>
                        <Grid item ls={6} md={6} sm={6} xs={6} xl={6}>
                            <BoxContainer>
                                <span className="item item-left">Service Level (120 seconds)</span>
                                <span className="item">{serviceLevel}</span>
                            </BoxContainer>
                        </Grid>
                        <Grid item ls={6} md={6} sm={6} xs={6} xl={6}>
                            <BoxContainer style={{ borderLeft: 'none' }}>
                                <span className="item item-left">Service Level (500 seconds)</span>
                                <span className="item">{serviceLevel500}</span>
                            </BoxContainer>
                        </Grid>

                    </Grid>
                )
                    : (
                        <NoData>No data found</NoData>
                    )}
            </ChildrenStyles>
        </RootStyle>
    );
};

export default WaitTime;
