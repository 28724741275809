import React, { useState, useEffect } from 'react';
import { alpha, styled, useTheme } from '@mui/material/styles';
import { Card, Typography, Box, Grid } from '@mui/material';
import { Icon } from '@iconify/react';
import { awsConnect, awsConnectInstanceId } from '../../../api/awsConnect';
import momentTZ from 'moment-timezone';
import moment from 'moment';
import { useSelector } from 'react-redux';
import {
    Timeline,
    TimelineItem,
    TimelineSeparator,
    TimelineContent,
    TimelineDot,
    TimelineConnector
} from '@mui/lab';
import Backend, { backendURL } from '../../../api/backend';
import _ from 'lodash';

const RootStyle = styled(Card)(({ theme }) => ({
    boxShadow: 'none',
    // textAlign: 'center',
    padding: theme.spacing(2, 2),
    color: theme.palette.common.black,
    backgroundColor: theme.palette.customBlackThemeColor.cardColor,
    height: '30%',
    width: '100%'
}));

const ChildrenStyles = styled(Box)(({ theme }) => ({
    padding: theme.spacing(0, 0)
}));
const ChildItem = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    '& .title': {
        fontSize: '0.5rem',
        fontWeight: 'bold'
    },
    '& .smallTitle': {
        fontSize: '0.5rem',
        fontWeight: 'bold',
        display: 'flex',
        justifyContent: 'space-between',
        alignItem: 'center',
        '& .sub': {
            fontSize: '0.5rem',
            color: theme.palette.grey[600]
        }
    }
}));

const ChildContainer = styled('span')(() => ({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around'
}));
const SmallBox = styled('div')(({ color }) => ({
    height: '.5rem',
    width: '.5rem',
    border: `1px solid ${color}`,
    backgroundColor: color,
    alignSelf: 'center',
    marginRight: '3px'
}));

const AgentPerformance = ({ agentname }) => {
    const theme = useTheme();
    const data = useSelector((state) => state.recentlyActivity);
    const [totalCalls, settotalCalls] = useState(0);
    const [totalOutboundCalls, settotalOutboundCalls] = useState(0);
    const [totalInboundCalls, settotalInboundCalls] = useState(0);
    const [totalTalkTime, settotalTalkTime] = useState(0);
    const [averageTalkTime, setaverageTalkTime] = useState(0);
    
    const getRecentActivities = async () => {
        try {
            const data = await Backend({
                method: 'GET',
                url: backendURL.getagentperformance + "?username=" + agentname
            });
            settotalCalls(data.data.data.totalCalls);
            settotalOutboundCalls(data.data.data.totalOutboundCalls);
            settotalInboundCalls(data.data.data.totalInboundCalls);
            settotalTalkTime(data.data.data.totalTalkTime);
            setaverageTalkTime(data.data.data.averageTalkTime);
        } catch (error) {
            console.log('Getting error in fetching of recently activity');
            console.log(error);
        }

        //setTimeout(getRecentActivities, 10000);
    };
    useEffect(() => {

        let interval = setInterval(() => {
            getRecentActivities();
        }, 10000);
        return () => {
            clearInterval(interval);
        };

    }, [agentname]);
    return (
        <RootStyle>
            <Typography
                variant="subtitle2"
                sx={{
                    opacity: 0.72,
                    fontSize: 14,
                    color: alpha(theme.palette.customBlackThemeColor.sidebarTextColor)
                }}
            >
                Agent Live Performance
      </Typography>
            <ChildrenStyles>
                <Grid container direction="column" spacing={5}>

                    <Grid item>
                        <ChildContainer>
                            <ChildItem>
                                <span className="smallTitle">
                                    <SmallBox color={theme.palette.chart.yellow[0]} />

                                    {totalCalls}

                                </span>
                                <span className="smallTitle">Total Calls</span>
                            </ChildItem>
                      
                            <ChildItem>
                                <span className="smallTitle">
                                    <SmallBox color={theme.palette.customBlackThemeColor.sidebarTextColor} />{totalOutboundCalls}
                                </span>
                                <span className="smallTitle">Total Outbound Calls</span>
                            </ChildItem>
                            <ChildItem>
                                <span className="smallTitle">
                                    <SmallBox color={theme.palette.customBlackThemeColor.sidebarTextColor} />
                                    {totalInboundCalls}
                                </span>
                                <span className="smallTitle">Total Inbound Calls</span>
                            </ChildItem>
                      
                           
                            <ChildItem>
                                <span className="smallTitle">
                                    <SmallBox color={theme.palette.chart.yellow[0]} />

                                    {totalTalkTime}
                                </span>
                                <span className="smallTitle">Total Talk Time</span>
                            </ChildItem>
                            <ChildItem>
                                <span className="smallTitle">
                                    <SmallBox color="pink" />{averageTalkTime}
                                </span>
                                <span className="smallTitle">Average Talk Time</span>
                            </ChildItem>
                        </ChildContainer>
                    </Grid>
                </Grid>
            </ChildrenStyles>
        </RootStyle>
    );
};

export default AgentPerformance;
