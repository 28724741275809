// material
import 'amazon-connect-streams';
// import 'amazon-connect-chatjs';
import React from 'react';
import moment from 'moment';
import { Box, Grid, Container, Typography, Button } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import arrowIosForwardFill from '@iconify/icons-eva/arrow-ios-forward-fill';
import { Icon } from '@iconify/react';
import { alpha, styled } from '@mui/material/styles';
// components
import Page from '../components/Page';
import axios from 'axios';
import {
    // AppTasks,
    // AppNewsUpdate,
    // AppOrderTimeline,
    // AppCurrentVisits,
    // AppWebsiteVisits,
    // AppTrafficBySite,
    // AppCurrentSubject,
    // AppConversionRates,
    // TotalCustomers,
    // ActiveAgents,
    ContactsInQueue,
    AgentAvailability,
    RecentActivity,
    WaitTime,
    ACDTalkTime,
    HandlingTime,
    Contacts,
    WrapUpCode
} from '../components/_dashboard/app';
import AWSModal from 'src/layouts/dashboard/AWSModal';
import { ColorModeContext } from 'src/theme';
import { awsConnect, awsConnectInstanceId } from 'src/api/awsConnect';
import { currentMetrics, historicalValues } from 'src/api/HistoricalMetrics';

// ----------------------------------------------------------------------

const ButtonRootStyle = styled(Button)(({ theme }) => ({
    boxShadow: 'none',
    textAlign: 'center',
    padding: 0,
    height: 50,
    top: 16,
    color: theme.palette.info.darker,
    backgroundColor: theme.palette.info.lighter,
    ':hover': {
        color: 'white'
    }
}));
const ContentContainer = styled('div')(() => ({
    display: 'flex',
    justifyContent: 'center',
    width: '94vw',
    border: '1px solid red'
}));
export default function AgentApp() {
    const [open, setOpen] = React.useState(false);
    const [metricsData, setMetricsData] = React.useState(null);
    const handleOpen = () => {
        setOpen((prev) => !prev);
    };
    const handleClose = () => setOpen(false);
    const [connected, setConnected] = useState(false);
    const [contactInQueue, setContactInQueue] = useState(0);
    const [agentAvailable, setAgentAvailable] = useState(0);
    const [agentOnline, setAgentOnline] = useState(0);
    const [totalAgents, setTotalAgents] = useState(0);
    const [agentOnCall, setAgentOnCall] = useState(0);
    const [contactsHandled, setContactshandled] = useState(0);
    // const [contactsQueued, setContactsQueued] = useState();
    const [handleTime, setHandleTIme] = useState();
    const [totalHandledTime, setTotalHandledTime] = useState({
        hour: '00',
        minutes: '00',
        seconds: '00'
    });
    const [averageHandleTime, setAverageHandleTime] = useState({
        hour: '00',
        minutes: '00',
        seconds: '00'
    });
    const [queueInfo, setQueueInfo] = useState();
    const containerRef = useRef(null);
    const { toggleColorMode, mode } = React.useContext(ColorModeContext);

    useEffect(() => {
        // Get Current Metric Data
        let currentMetricsInterval = setInterval(() => {
            awsConnect.getCurrentMetricData(
                {
                    InstanceId: awsConnectInstanceId,
                    CurrentMetrics: currentMetrics,
                    Filters: {
                        Channels: ['VOICE'], //[('CHAT', 'TASK')],
                        Queues: [
                            'arn:aws:connect:eu-west-2:355721060766:instance/5729b379-f5fe-4017-aac1-15ba8ed5e9b6/queue/d09c4cb0-208c-4df2-9d45-9927eec3df42'
                            // 'arn:aws:connect:us-east-1:767282783672:instance/9c763e34-8f80-4824-9b7c-9857f7a0dc07/queue/5f6cbce6-5167-4f4a-a26e-971f860bcb20'
                            // 'arn:aws:connect:us-east-1:767282783672:instance/9c763e34-8f80-4824-9b7c-9857f7a0dc07/queue/7f1c0d40-7a2b-409c-8fa8-833714dbc1ef'
                            // 'arn:aws:connect:us-east-1:767282783672:instance/9c763e34-8f80-4824-9b7c-9857f7a0dc07/queue/962ae2bb-14f8-4f79-adf4-45fee8f513cb'
                        ]
                    },
                    Groupings: ['QUEUE', 'CHANNEL']
                    // MaxResults: 20
                },
                (err, data) => {
                    if (data) {
                        setMetricsData(data);
                        // console.log(data);
                        if (data.MetricResults.length > 0) {
                            data.MetricResults.map((item) => {
                                item.Collections.map((colItem) => {
                                    if (colItem.Metric.Name === 'CONTACTS_IN_QUEUE') {
                                        setContactInQueue(colItem.Value);
                                    } else if (colItem.Metric.Name === 'AGENTS_AVAILABLE') {
                                        setAgentAvailable(colItem.Value);
                                    } else if (colItem.Metric.Name === 'AGENTS_ONLINE') {
                                        setAgentOnline(colItem.Value);
                                    } else if (colItem.Metric.Name === 'AGENTS_ON_CALL') {
                                        setAgentOnCall(colItem.Value);
                                    }
                                });
                            });
                        }
                    } else if (err) {
                        console.log('Error found in getting data of aws connect');
                        console.log(err);
                        console.log(err.stack);
                    }
                }
            );
        }, 3000);
        return () => {
            console.log('interval cleared');
            clearInterval(currentMetricsInterval);
        };
    }, []);

    useEffect(() => {
        // List Agent Statuses
        // Get all agents
        awsConnect.listAgentStatuses(
            {
                InstanceId: awsConnectInstanceId
            },
            (err, data) => {
                if (err) {
                    console.log('Error is coming of list agents....');
                    console.log(err);
                } else if (data) {
                    console.log('Data is here of list agents...');
                    console.log(data);
                    if (data.AgentStatusSummaryList.length > 0) {
                        setTotalAgents(data.AgentStatusSummaryList.length);
                    }
                }
            }
        );
    }, []);

    // -----
    useEffect(() => {
        // Minutes should be divisible by 5
        setInterval(() => {
            const currentMinute = parseInt(new Date().getMinutes() / 5) * 5;
            const endTime = moment().set('minute', currentMinute).set('second', 0).unix();
            const subHours = moment().subtract(23, 'hours');
            const startTime = moment(subHours).set('minute', currentMinute).set('second', 0).unix();
            // console.log(endTime, startTime);
            awsConnect.getMetricData(
                {
                    InstanceId: awsConnectInstanceId,
                    StartTime: startTime,
                    EndTime: endTime,
                    Filters: {
                        Channels: ['VOICE'],
                        Queues: ['d09c4cb0-208c-4df2-9d45-9927eec3df42']
                    },
                    Groupings: ['QUEUE', 'CHANNEL'],
                    HistoricalMetrics: historicalValues
                },
                (err, data) => {
                    if (err) {
                        console.log('Error found in ACDTalkTime for getMetricData .... ');
                        console.log(err);
                    } else if (data) {
                        if (data.MetricResults) {
                            const [Collections] = data.MetricResults;
                            Collections?.Collections?.forEach((item) => {
                                // console.log(item.Metric, item.Value);
                                if (item.Metric.Name === 'CONTACTS_HANDLED') {
                                    setContactshandled(item.Value);
                                }
                                if (item.Metric.Name === 'HANDLE_TIME') {
                                    setHandleTIme(item.Value);
                                }
                            });
                        }
                    }
                }
            );
        }, [20000]);
    }, []);
    useEffect(() => {
        if (handleTime && contactsHandled) {
            // console.log('i am here', handleTime)
            const totalTime = moment
                .utc(handleTime * 1000)
                .format('HH:mm:ss')
                .split(':');
            const avgSeconds = parseFloat((handleTime / contactsHandled).toFixed(2));
            const averageTime = moment
                .utc(avgSeconds * 1000)
                .format('HH:mm:ss')
                .split(':');
            if (totalTime[0] === '00' && totalTime[1] === '00' && totalTime[2] === '00') {
                setTotalHandledTime(totalHandledTime);
            } else {
                setTotalHandledTime({
                    hour: totalTime[0] === '00' ? '00' : totalTime[0],
                    minutes: totalTime[1] === '00' ? '00' : totalTime[1],
                    seconds: totalTime[2] === '00' ? '00' : totalTime[2]
                });
            }
            if (averageTime[0] === '00' && averageTime[1] === '00' && averageTime[2] === '00') {
                setAverageHandleTime(totalHandledTime);
            } else {
                setAverageHandleTime({
                    hour: averageTime[0] === '00' ? '00' : averageTime[0],
                    minutes: averageTime[1] === '00' ? '00' : averageTime[1],
                    seconds: averageTime[2] === '00' ? '00' : averageTime[2]
                });
            }
        }
    }, [handleTime, contactsHandled]);

    return (
        <Page title="Home">
            <Container maxWidth="xl">

                <Grid container spacing={2}>
                  
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Grid container direction="column" spacing={2}>
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                <AgentAvailability
                                    totalAgents={totalAgents}
                                    agentAvailable={agentAvailable}
                                    agentOnline={agentOnline}
                                />
                            </Grid>

                        </Grid>
                    </Grid>
                </Grid>
            </Container>
        </Page>
    );
}
