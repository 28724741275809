import React from 'react';
import faker from 'faker';
import PropTypes from 'prop-types';
import { Icon } from '@iconify/react';
import googleFill from '@iconify/icons-eva/google-fill';
import twitterFill from '@iconify/icons-eva/twitter-fill';
import facebookFill from '@iconify/icons-eva/facebook-fill';
import linkedinFill from '@iconify/icons-eva/linkedin-fill';
// material
import {
  Box,
  Grid,
  Card,
  Paper,
  Typography,
  CardHeader,
  CardContent,
  useTheme
} from '@mui/material';
// utils
import { fShortenNumber } from '../../../utils/formatNumber';
import { ColorModeContext } from 'src/theme';

// ----------------------------------------------------------------------

const SOCIALS = [
  {
    name: 'FaceBook',
    value: faker.datatype.number(),
    icon: <Icon icon={facebookFill} color="#1877F2" width={32} height={32} />
  },
  {
    name: 'Google',
    value: faker.datatype.number(),
    icon: <Icon icon={googleFill} color="#DF3E30" width={32} height={32} />
  },
  {
    name: 'Linkedin',
    value: faker.datatype.number(),
    icon: <Icon icon={linkedinFill} color="#006097" width={32} height={32} />
  },
  {
    name: 'Twitter',
    value: faker.datatype.number(),
    icon: <Icon icon={twitterFill} color="#1C9CEA" width={32} height={32} />
  }
];

// ----------------------------------------------------------------------

SiteItem.propTypes = {
  site: PropTypes.object
};

function SiteItem({ site, mode, theme }) {
  const { icon, value, name } = site;
  const bgColor =
    mode === 'light' ? theme.palette.common.white : theme.palette.customBlackThemeColor.charts;
  const textColor = mode === 'light' ? theme.palette.common.black : theme.palette.common.white;

  return (
    <Grid item xs={6}>
      <Paper variant="outlined" sx={{ py: 2.5, textAlign: 'center', backgroundColor: bgColor }}>
        <Box sx={{ mb: 0.5 }}>{icon}</Box>
        <Typography sx={{ color: textColor }} variant="h6">
          {fShortenNumber(value)}
        </Typography>
        <Typography variant="body2" sx={{ color: 'text.secondary', fontWeight: 'bold' }}>
          {name}
        </Typography>
      </Paper>
    </Grid>
  );
}

export default function AppTrafficBySite() {
  const { toggleColorMode, mode } = React.useContext(ColorModeContext);
  const theme = useTheme();
  const bgColor =
    mode === 'light' ? theme.palette.common.white : theme.palette.customBlackThemeColor.charts;

  return (
    <Card sx={{ backgroundColor: bgColor }}>
      <CardHeader
        sx={{
          color: mode === 'light' ? theme.palette.common.black : theme.palette.common.white
        }}
        title="Traffic by Site"
      />
      <CardContent>
        <Grid container spacing={2}>
          {SOCIALS.map((site) => (
            <SiteItem mode={mode} theme={theme} key={site.name} site={site} />
          ))}
        </Grid>
      </CardContent>
    </Card>
  );
}
