import React, { useState, useEffect } from 'react';
import { alpha, styled, useTheme } from '@mui/material/styles';
import { Card, Typography, Box, Button } from '@mui/material';
import { Icon } from '@iconify/react';
import { awsConnect, awsConnectInstanceId } from '../../../api/awsConnect';
import momentTZ from 'moment-timezone';
import moment from 'moment';
import { useSelector } from 'react-redux';
import {
  Timeline,
  TimelineItem,
  TimelineSeparator,
  TimelineContent,
  TimelineDot,
  TimelineConnector
} from '@mui/lab';
import Backend, { backendURL } from '../../../api/backend';
import _ from 'lodash';

const RootStyle = styled(Card)(({ theme }) => ({
    boxShadow: 'none',
    borderRadius:'16px',
  // textAlign: 'center',
  padding: theme.spacing(2, 2),
  color: theme.palette.common.black,
  // backgroundColor: theme.palette.error.lighter,
  backgroundColor: theme.palette.customBlackThemeColor.cardColor,
  height: '100%',
    width: '100%'
   
}));
const ActivityHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
  '& .recentlyTime': {
      color: theme.palette.grey[600],
      fontSize:'12px'
  }
}));
const ChildrenStyles = styled(Box)(({ theme }) => ({
  //   border: '1px solid black'
  padding: theme.spacing(0, 0),
    height: '28rem',
    display: 'block',
  flexDirection: 'row',
   paddingTop: 1,
    alignItems: 'center',
    overflowX: 'hidden',
    overflowY: 'auto',
}));
const TimeLineCustomItem = styled(TimelineItem)(({ theme }) => ({
  '&::before': {
    content: 'none',
    width: 0
  }
}));
const NoData = styled('p')(({ theme }) => ({
  textAlign: 'center',
    marginTop: '1rem',
    width: '100%',
  height:'100%',
  color: theme.palette.grey['500']
}));
const ActivityContent = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  flexDirection: 'row',
  '& .voice': {
    color: theme.palette.grey[600],
    fontSize: '0.6rem'
  },
  '& .content-1': {
    display: 'flex',
      alignItem: 'center',
      fontSize: '12px',
    '.icon': {
      alignSelf: 'center'
    },
    '.phone': {
      display: 'flex',
      flexDirection: 'column',
        marginLeft: '4px'
      
    }
  },
  '& .content-2': {
    display: 'flex',
    flexDirection: 'column',
      marginLeft: '6px',
      fontSize: '12px'
    }
    ,
    '& .content-3': {
        display: 'flex',
        flexDirection: 'column',
        marginLeft: '6px',
        fontSize: '12px'
    },
    '.wrap-up': {
        display: 'flex',
        flexDirection: 'column',
        marginLeft: '4px',
        color:'#54D62C'

    }
}));
const ActivityFooter = styled('div')(({ theme }) => ({
  marginTop: '10px',
  '.title': {
    fontSize: '12px'
  },
  '.content': {
    color: theme.palette.grey['500']
  }
}));
const activityList = [
  {
    recentlyTime: '2 hour ago',
    type: 'OUTBOUND',
    number: '(619) 972-7326',
    name: 'TechinalSupport',
    time: '2019-04-25 10:25:22'
  },
  {
    recentlyTime: '3 hour ago',
    type: 'INBOUND',
    number: '(619) 972-7326',
    name: 'GeneralSupport',
    time: '2019-04-25 10:25:22'
  }
];

const RecentActivity = ({agentname }) => {
  const theme = useTheme();
  const data = useSelector((state) => state.recentlyActivity);
  const [recentlyActivityData, setRecentlyActivityData] = useState([]);
  const getRecentActivities = async () => {
    try {
      const data = await Backend({
          method: 'GET',
          url: backendURL.getRecentlyActivities + "?username=" + agentname
      });
      setRecentlyActivityData(_.sortBy(data.data.body.Items, ['timestamp']).reverse());
    } catch (error) {
      console.log('Getting error in fetching of recently activity');
      console.log(error);
      }

      //setTimeout(getRecentActivities, 10000);
  };
    useEffect(() => {
       
        let interval = setInterval(() => {
            getRecentActivities();
        }, 10000);
        return () => {
            clearInterval(interval);
        };
        
    }, [agentname]);
  return (
    <RootStyle>
      <Typography
        variant="subtitle2"
        sx={{
          opacity: 0.72,
          fontSize: 16,
          // color: alpha(theme.palette.customBlackThemeColor.sidebarTextColor)
          color: theme.palette.common.black
        }}
      >
        Recent Activities
      </Typography>
      <ChildrenStyles>
        <Timeline>
          {recentlyActivityData?.length > 0 ? (
            [...recentlyActivityData].map((item, index) => {
              const startingTime = moment(item?.startTime).format('YYYY-MM-DD hh:mm:ss');
              // const startOf = moment(startingTime, 'YYYY-MM-DD hh:mm:ss').fromNow();

              // const startOf = moment(item?.startTime).fromNow();
                const startOf = momentTZ(item?.startTime).tz('Europe/London').fromNow();

              return (
                <TimeLineCustomItem key={index} position="right">
                  <TimelineSeparator>
                    <TimelineDot />
                    {data?.data.length - 1 !== index && <TimelineConnector />}
                  </TimelineSeparator>
                  <TimelineContent>
                    <ActivityHeader>
                              <span className="recentlyTime">{`${startOf}`}</span>
                              
                      <Button
                        variant="outlined"
                        color={item?.callType === 'OUTBOUND' ? 'success' : 'info'}
                        size="small"
                      >
                        {item?.callType}
                      </Button>
                    </ActivityHeader>
                    <ActivityContent>
                      <div className="content-1">
                        <div className="icon">
                          <Icon
                            icon="cil:phone"
                            width={16}
                            height={16}
                            color={theme.palette.customBlackThemeColor.sidebarTextColor}
                          />
                        </div>
                        <div className="phone">
                                      <span>{item?.phoneNumber}</span>
                                      
                         
                        </div>
                      </div>
                      <div className="content-2">
                        
                                  <span className="voice">{`${startingTime}`}</span>
                                 
                              </div>
                             
                          </ActivityContent>
                          <ActivityContent>
                              <div className="content-3">
                                  
                                  <span className="wrap-up"> {`${item?.wrapCodes}`}</span>

                              </div>
                          </ActivityContent>
                    
                  </TimelineContent>
                </TimeLineCustomItem>
              );
            })
          ) : (
            <NoData>No recent activities</NoData>
          )}
        </Timeline>
      </ChildrenStyles>
    </RootStyle>
  );
};

export default RecentActivity;
