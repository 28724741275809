import React, { useState, useRef, useEffect, useMemo } from 'react';
// import { useStore } from '../../store';
import 'amazon-connect-streams';
import { Icon } from '@iconify/react';
import moment from 'moment';
import {
    Box,
    Button,
    Input,
    ButtonsContainer,
    CallButton,
    ButtonInnerContainer,
    EndButton,
    HoldCall,
    ResumeCall,
    MuteCall
} from './atom';

import { awsConnect, awsConnectInstanceId } from '../../api/awsConnect';
import Backend, { backendURL } from '../../api/backend';
import actionType from '../../redux/actions/actionTypes';
import { Snackbar, Alert, Slide } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
function TransitionRight(props) {
    return <Slide {...props} direction="right" />;
}
export default (props,{placeholder, onCall,role }) => {
    let newAgent;
    const [number, setNumber] = React.useState('');
    const containerRef = useRef(null);
    const [connected, setConnected] = useState(true);
    const [isMuteAgent, setIsMuteAgent] = useState(false);
    const [holdCall, setHoldCall] = useState(false);
    const data = useSelector((state) => state.recentlyActivity);
    const dispatch = useDispatch();
    const [connectionId, setConnectionId] = useState(null);
    //   const [state, dispatch] = useStore();
    const buttons = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '*', '0', '#'];
    const [isOnCall, setIsOnCall] = useState(false);
    const selectedWrapCodes = role;
    let mAgent;
    const [openSnackBar, setOpenSnackBar] = useState({
        open: false,
        message: '',
        type: ''
    });
    const instanceUrl = 'https://universityofbirmingham-admissions.my.connect.aws/ccp-v2/';
    // const instanceUrl = 'https://ucassociates.my.connect.aws/ccp-v2/';

    function initializeCCP() {
        connect.core.initCCP(containerRef.current, {
            ccpUrl: instanceUrl,
            iframeTitle: 'Amazon Connect',
            loginPopup: true,
            loginPopupAutoClose: true,
            
            region: 'eu-west-2',
            softphone: {
                allowFramedSoftphone: true
               
            },
            pageOptions: {
                enableAudioDeviceSettings: true,
                enablePhoneTypeSettings: true
            },
           // ccpAckTimeout: 5000,
            //ccpSynTimeout: 3000,
            //ccpLoadTimeout: 10000
        });
        // console.log(containerRef.current);
        //connect.core.initCCP(containerDiv.get(0), abc);
    }
    useEffect(() => {
        console.log('initialized CCP');
        initializeCCP();
        //window.addEventListener("beforeunload", function (event) {
        //    if (mAgent != null) {
        //        let states = mAgent.getAgentStates();
        //        // "states" is an array of changeable states. You can filter the desired state to change by name.
        //        let offlineState = states.filter(state => state.name === "Offline")[0];
        //        // Change agent state
        //        mAgent.setState(offlineState, {
        //            success: function () {
        //                console.log("SetState succeeded");
        //            },
        //            failure: function () {
        //                console.log("SetState failed");
        //            }
        //        });
        //    }
        //});
    }, []);
    useEffect(() => {
        console.log('UseEffect - 1');
        connect.core.onInitialized(function () {
            console.log('Initialization is completed');
            setConnected(true);
        });
        connect.core.onAccessDenied(function () {
            console.log('Access denied');
        });
        connect.core.onViewContact(function (event) {
            const contactId = event.contactId;
            console.log(`ContactId === ${contactId}`);
        });
        connect.core.onAuthFail(function () {
            console.log('On Authentication Failed');
        });
        connect.core.onIframeRetriesExhausted(() => {
            console.log('We have run out of retries to reload the CCP Iframe');
        });
        connect.core.onSoftphoneSessionInit(function ({ connectionId }) {
            var softphoneManager = connect.core.getSoftphoneManager();
            if (softphoneManager) {
                // access session
                var session = softphoneManager.getSession(connectionId);
                console.log(session, connectionId);
            }
        });
        connect.contact(function (contact) {
            console.log(`----- contacts ------`);
            console.log(contact);
            console.log(`----- End Contacts ------`);
        });
        connect.agent(function (agent) {
            console.log('---- new agents is connected ----- ');
            mAgent = agent;
            console.log(agent);
            props.setAgentName(agent.getName());
            
            console.log('---- new agents is connected ----- ');
        });
    }, []);
    useEffect(() => {
        const eventBus = connect.core.getEventBus();
       
    });
    useEffect(() => {
        console.log('UseEffect - 2');
        connect.contact(function (contact) {
            contact.onIncoming(function (contact) {
                console.log('Incomming contact => ', contact);
            });

            contact.onRefresh(function (contact) {
                console.log('Refreshing contact => ', contact);
            });

            contact.onAccepted(function (contact) {
                console.log('Accepted contact => ', contact);
            });

            contact.onEnded(function () {
                console.log(`onConnected ------->(${contact.getContactId()})`);
                // setConnected(contact.getContactId());
                let findContactId = false;
                if (data?.data && data?.data?.length > 0) {
                    findContactId = !!data.data?.find((item) => item?.contactId === contact.getContactId());
                }
                if (findContactId === false) {
                    awsConnect.describeContact(
                        {
                            InstanceId: awsConnectInstanceId,
                            // ContactId: 'c5aa4ffa-0cf3-4d2f-abd6-a6b826fb499f'
                            ContactId: contact.getContactId()
                        },
                        function (err, data) {
                            if (err) {
                                console.log(err, err.stack);
                            } else {
                                console.log(data);
                                
                                var conParams = contact.getConnections();
                                var dialedNumber = conParams[1].getEndpoint().stripPhoneNumber();
                                var agent = new connect.Agent();
                                const channel = data?.Contact?.Channel;
                                const callType = data?.Contact?.InitiationMethod;
                                const startTime = data?.Contact?.InitiationTimestamp;
                                const endTime = data?.Contact?.DisconnectTimestamp;
                                const phoneNumber = dialedNumber;
                                const name = agent.getName();
                                const agentID = data?.Contact.AgentInfo.Id;

                                console.log('recent activity calling useMemo.....');
                                addRecentActivity({
                                    id: contact.getContactId(),
                                    channel,
                                    callType,
                                    startTime,
                                    endTime,
                                    phoneNumber,
                                    agentID,
                                    name,
                                    wrapCodes: role || [],
                                    timestamp: Date.now()
                                });


                            }
                        }
                    );
                }
            });

            contact.onMissed(function () {
                let id = contact.getContactId();
                let conParams = contact.getConnections();
                let queue = contact.getQueue();
                let dialedNumber = conParams[1].getEndpoint().stripPhoneNumber();
                var agent = new connect.Agent();
                const name = agent.getName();
                console.log(`onMissed ------->(${id})`);
                // setConnected(contact.getContactId());
                let findContactId = false;
                if (data?.data && data?.data?.length > 0) {
                    findContactId = !!data.data?.find((item) => item?.contactId === contact.getContactId());
                }
                if (findContactId === false) {
                    awsConnect.describeContact(
                        {
                            InstanceId: awsConnectInstanceId,
                            // ContactId: 'c5aa4ffa-0cf3-4d2f-abd6-a6b826fb499f'
                            ContactId: id
                        },
                        function (err, data) {
                            if (err) {
                                console.log(err, err.stack);
                            } else {
                                console.log(data);




                                const channel = data?.Contact?.Channel;
                                const callType = data?.Contact?.InitiationMethod;
                                const startTime = data?.Contact?.InitiationTimestamp;
                                
                                const endTime = data?.Contact?.DisconnectTimestamp;
                                const phoneNumber = dialedNumber;

                                const agentID = '';

                                console.log('recent activity calling useMemo.....');
                                addRecentActivity({
                                    id: id,
                                    channel,
                                    callType,
                                    startTime,
                                    endTime,
                                    phoneNumber,
                                    agentID,
                                    name,
                                    wrapCodes: "Missed Call",
                                    timestamp: Date.now()
                                });


                            }
                        }
                    );
                }
            });

            contact.onConnected(function () {
                
                
            });
        });
    }, [connected]);
    
    function makeACall() {
        console.log('Making call');
        console.log(`number is ${number}`);
        // var agent = new connect.Agent();
        var agent = new connect.Agent();

        var contact = new connect.Contact();
        // var noToDial = '+18333782183';
        const endpoint = connect.Endpoint.byPhoneNumber(number);
        console.log('make a call to phone number');
        agent.connect(endpoint, {
            success: () => {
                console.log('success');
                setIsOnCall(true);
            },
            failure: () => {
                console.log('failures');
                setIsOnCall(false);
                setOpenSnackBar({
                    type: 'error',
                    message: 'failed calling...',
                    open: true
                });
            }
        });
    }
    function hangUpCall() {
        console.log('HangUp Call');
        var agent = new connect.Agent();
        var contact = agent.getContacts(connect.ContactType.VOICE)[0];
        contact.getAgentConnection().destroy();
    }
    function onClickCall() {
        if (number === '') {
            setOpenSnackBar({
                type: 'error',
                message: 'Please Input your phone number',
                open: true
            });
        } else {
            makeACall();
        }
    }
    function onCloseSnackBar() {
        setOpenSnackBar({
            type: '',
            message: '',
            open: false
        });
    }
    function onClickEndCall() {
        setIsOnCall(false);
        hangUpCall();
    }
    function onHoldCall() {
        var agent = new connect.Agent();
        var contact = agent.getContacts(connect.ContactType.VOICE)[0];
        contact.getAgentConnection().hold({
            success: function (data) {
                console.log(data);
                console.log('Hold call successfully');
                setHoldCall(true);
            },
            failure: function () {
                console.log('Failed to hold call');
            }
        });
    }
    function onResumeCall() {
        var agent = new connect.Agent();
        var contact = agent.getContacts()[0];
        // console.log(`contact is here ---- >> ${contact.getContactId()}`);
        // var contact = agent.getC
        contact.getAgentConnection().resume({
            success: function () {
                console.log('resume call successfully');
                setHoldCall(false);
            },
            failure: function () {
                console.log('Failed to resume call');
            }
        });
        // contact.addConnection(connect.Endpoint.byPhoneNumber(number), {
        //   success: function () {
        //     console.log('resume call successfully');
        //     setHoldCall(false);
        //   },
        //   failure: function () {
        //     console.log('Failed to resume call');
        //   }
        // });
    }
    function muteAgent() {
        var agent = new connect.Agent();
        agent.mute();
        setIsMuteAgent(true);
    }
    function unmuteAgent() {
        var agent = new connect.Agent();
        agent.unmute();
        setIsMuteAgent(false);
    }
    const addRecentActivity = async (activity) => {
        try {
            
            const data = await Backend({
                method: 'POST',
                url: backendURL.addRecentlyActivities,
                data: {
                    ...activity
                }
            });
            console.log('Recent activity added');
            props.setContactId(activity.id);
            console.log(data);
        } catch (error) {
            console.log('error found in setting data....');
            console.log(error);
        }
    };
    return (
        <Box opened={true}>
           
            <div
                id="containerDiv"
                ref={containerRef}
                style={{
                    marginTop: '0.1rem',
                    marginBottom: '0.1rem',
                    display: 'block',
                    // display: 'none',
                    height: '100%',
                    width: '99%'
                }}
            />
        </Box>
    );
};
