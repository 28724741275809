import AWS from 'aws-sdk';

export const awsConnectInstanceId = process.env.REACT_APP_AWS_CONNECT_INSTANCE_ID;

export const awsConnect = new AWS.Connect({
  apiVersion: '2017-08-08',
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY,
  region: process.env.REACT_APP_AWS_REGION
});
