import React from 'react';
import { useState } from 'react';
import { Outlet } from 'react-router-dom';
// material
import { styled, alpha } from '@mui/material/styles';
//
import DashboardNavbar from './DashboardNavbar';
import DashboardSidebar from './DashboardSidebar';
import { ColorModeContext } from 'src/theme';
import DashboardFooter from './DashboardFooter';
import DashboardRightSideBar from './DashboardRightSideBar';

// ----------------------------------------------------------------------

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 92;

const MainStyle = styled('div')(({ theme }) => ({
  flexGrow: 1,
  overflow: 'hidden',
  minHeight: '100%',
  paddingTop: APP_BAR_MOBILE + 24,
  paddingBottom: theme.spacing(10),
  [theme.breakpoints.up('lg')]: {
    paddingTop: APP_BAR_DESKTOP + 24,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  }
}));

// ----------------------------------------------------------------------

export default function DashboardLayout() {
  const [open, setOpen] = useState(false);
  const [openRightBar, setOpenRightBar] = useState(false);
  const { toggleColorMode, mode } = React.useContext(ColorModeContext);

  const RootStyle = styled('div')(({ theme }) => ({
    display: 'flex',
    minHeight: '100%',
    overflow: 'hidden',
    backgroundColor:
      mode === 'light'
        ? alpha(theme.palette.background.default, 0.72)
        : alpha(theme.palette.customBlackThemeColor.lightGray, 1)
  }));

  return (
    <>
      <RootStyle>
        <DashboardNavbar
          onOpenSidebar={() => setOpen(true)}
          openRightBar={openRightBar}
          setOpenRightBar={setOpenRightBar}
        />
        <DashboardFooter />
              {/* <DashboardSidebar isOpenSidebar={open} onCloseSidebar={() => setOpen(false)} />*/}
        {/* <DashboardRightSideBar
          isOpenSidebar={openRightBar}
          onCloseSidebar={() => setOpenRightBar(false)}
        /> */}
        <MainStyle>
          <Outlet />
        </MainStyle>
      </RootStyle>
    </>
  );
}
