import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@iconify/react';
import menu2Fill from '@iconify/icons-eva/menu-2-fill';
// material
import { alpha, styled } from '@mui/material/styles';
import { Box, Stack, AppBar, Toolbar, IconButton } from '@mui/material';
// components
import { MHidden } from '../../components/@material-extend';
//
import Searchbar from './Searchbar';
import AccountPopover from './AccountPopover';
import LanguagePopover from './LanguagePopover';
import NotificationsPopover from './NotificationsPopover';
import ThemeChange from './ThemeChange';
import { ColorModeContext } from 'src/theme';
import Caller from './Caller';
import Bars from './Bars';
import Logo from '../../components/Logo';

// ----------------------------------------------------------------------

export const DRAWER_WIDTH = 1;
export const APPBAR_MOBILE = 64;
export const APPBAR_DESKTOP = 52;

DashboardNavbar.propTypes = {
  onOpenSidebar: PropTypes.func
};

export default function DashboardNavbar({ onOpenSidebar, openRightBar, setOpenRightBar }) {
  const { toggleColorMode, mode } = React.useContext(ColorModeContext);

  const RootStyle = styled(AppBar)(({ theme }) => ({
    // position: 'relative',
    boxShadow: 'none',
    backdropFilter: 'blur(6px)',
    WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
    backgroundColor:
      mode === 'light'
        ? alpha(theme.palette.background.default, 0.72)
        : alpha(theme.palette.customBlackThemeColor.lightGray, 0.72),
    [theme.breakpoints.up('lg')]: {
      width: `calc(100% - ${DRAWER_WIDTH + 1}px)`
    }
  }));

  const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
    minHeight: APPBAR_MOBILE,
    [theme.breakpoints.up('lg')]: {
      minHeight: APPBAR_DESKTOP,
      padding: theme.spacing(0, 2.5, 0, 5)
    }
  }));
  const LogoContainer = styled(Box)(() => ({
    position: 'relative',
    left: '50%',
    transform: 'translateX(-50%)',
    marginTop:'2px'
  }));
  return (
    <RootStyle>
      <ToolbarStyle>
        {/* <Stack direction="row" alignItems="center" spacing={{ xs: 0.5, sm: 1.5 }}>
          <NotificationsPopover />
          <Caller />
        </Stack> */}
        <MHidden width="lgUp">
          <IconButton onClick={onOpenSidebar} sx={{ mr: 1, color: 'text.primary' }}>
            <Icon icon={menu2Fill} color={mode === 'light' ? '#7f8c8d' : '#fff'} />
          </IconButton>
        </MHidden>
        <LogoContainer>
          <Logo />
        </LogoContainer>
        <Box sx={{ flexGrow: 1 }} />

        <Stack direction="row" alignItems="center" spacing={{ xs: 0.5, sm: 1.5 }}>
                  {/* <ThemeChange />*/}
          {/* <LanguagePopover /> */}
          {/* <NotificationsPopover /> */}
                  {/* <AccountPopover />/*}
          {/* <Bars openRightBar={openRightBar} setOpenRightBar={setOpenRightBar} /> */}
        </Stack>
      </ToolbarStyle>
    </RootStyle>
  );
}
