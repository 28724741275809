import { useContext, useEffect, useState, useMemo, useCallback, useLayoutEffect } from 'react';
import { render } from 'react-dom';
import { Box, Card, useTheme, Grid, Container, Typography, Button } from '@mui/material';
import Dailer from 'src/components/Dailer';
import Backend, { backendURL } from '../../../api/backend';
import { Icon } from '@iconify/react';
import bugFilled from '@iconify/icons-ant-design/bug-filled';
// material
import { alpha, styled } from '@mui/material/styles';


import { ColorModeContext } from 'src/theme';
import actionTypes from '../../../redux/actions/actionTypes';


const RootStyle = styled(Card)(({ theme, mode }) => ({
    boxShadow: 'none',
    padding: theme.spacing(2, 2),
    color: theme.palette.error.darker,
    backgroundColor: theme.palette.customBlackThemeColor.cardColor,
    height: 78,
    width: '100%'
}));

const ChildrenStyles = styled(Box)(({ theme }) => ({
    padding: theme.spacing(0, 0),
    display: 'flex',
    flexDirection: 'row',
    paddingTop: 1,
    zIndex: 1,
    width: '100%',
    height: '100%',
    padding: '1rem 0rem',
    '.MuiFormControlLabel-label': {
        color: theme.palette.common.black
    }
}));

const Child2ItemsStyle = styled(Box)(({ theme }) => ({
    boxShadow: 'none',
    padding: theme.spacing(2, 1),
    display: 'flex',
    flexDirection: 'row',
    paddingTop: 1,
    alignItems: 'left',
    justifyContent: 'space-between',
    marginLeft: 2,
    [theme.breakpoints.up('md')]: {
        width: 200
    },
    [theme.breakpoints.up('lg')]: {
        width: 300
    },
    [theme.breakpoints.up('lg')]: {
        width: 200
    },

    height: 100,
    border: `1px solid ${alpha(theme.palette.customBlackThemeColor.sidebarTextColor, 0.7)}`
}));
const WrapCodeContainer = styled('div')(({ theme }) => ({
    overflow: 'hidden',

    height: '100%',
    width: '100%',
    '& > :not(:first-child)': {
        marginTop: '.5rem'
    }
}));
const ContentWrapper = styled(Box)(({ theme }) => ({
    width: 250,
    height: 150,
    border: '1px solid #000'
}));
// ----------------------------------------------------------------------

const TOTAL = 10;
const WrapItem = styled('div')(({ theme, active }) => ({
    width: '100%',
    border: active
        ? `1px solid ${theme.palette.chart.blue[0]}`
        : `1px solid ${theme.palette.grey[200]}`,
    padding: '0.4rem',
    //borderRadius: '10px',
    cursor: 'pointer',
    color: theme.palette.common.black,
    backgroundColor: theme.palette.grey[200],
    fontSize: '0.8rem'
}));
export default function WrapUpCodeSelect(props, {contactid }) {

    const theme = useTheme();
    const { toggleColorMode, mode } = useContext(ColorModeContext);
    const [isdisabled, setisdisabled] = useState(true);
    const [addrtype, setAddrtype] = useState(["Please Select","Accommodation", "Application", "Clearing","Course Enquiries", "Current Student", "DBS","Finance or Funding","Other", "Other Institution"])
    const [wrapupCode, setWrapupCode] = useState('');
   const Add = addrtype.map(Add => Add
    )
    const handleAddrTypeChange = (e) => {
        if (addrtype[e.target.value] !== "Please Select")
            setisdisabled(false);
        else
            setisdisabled(true);
        props.setRole((addrtype[e.target.value]));
        setWrapupCode(addrtype[e.target.value]);
    };

    const handleSubmitWrapupCode = (e) => {
        if (props.contactid != '' && props.contactid != undefined) {
            updateActivity({
                id: props.contactid,
                wrapCodes: wrapupCode
            });
        }
    };
    const updateActivity = async (activity) => {
        try {
            const data = await Backend({
                method: 'POST',
                url: backendURL.updateActivity,
                data: {
                    ...activity
                }
            });
            console.log('Recent activity updated with wrap up code');
            console.log(data);
        } catch (error) {
            console.log('error found in setting data....');
            console.log(error);
        }
    };
    return (
        <RootStyle mode={mode}>
            <Typography
                variant="subtitle2"
                sx={{
                    opacity: 0.72,
                    fontSize: 14,
                    color: theme.palette.common.black
                }}
            >
                Wrap Up Code
      </Typography>
            <ChildrenStyles>
                <WrapCodeContainer>
                    < select style={{"width":"240px"}}
                        onChange={e => handleAddrTypeChange(e)}
                    >
                        {
                            Add.map((address, key) => <option key={key} value={key}>{address}</option>)
                        }
                    </select >
                    <button type="button" style={{ 'margin-left': '5px' }} disabled={isdisabled} onClick={e => handleSubmitWrapupCode(e)} >Submit</button>

                </WrapCodeContainer>
            </ChildrenStyles>
        </RootStyle>


    )


}
