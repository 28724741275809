import styled, { css } from 'styled-components';

export const Box = styled.div`
  width: 100%;
  //   max-width: 200px;
  height: 100%;
  padding: 0px;
  background: #263238;
  border-radius: 22.5px;
  visibility: hidden;
  opacity: 0;
  transform: translateY(30px);
  box-shadow: 0 5px 10px -5px rgba(0, 0, 0, 0.2);
  transition: opacity 0.15s ease-in, transform 0.25s ease-in, border-radius 0.3s ease-in-out,
    visibility 0s linear 0.3s;
  z-index: 10;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  ${(props) =>
    props.opened &&
    css`
      visibility: visible;
      opacity: 1;
      transform: translateY(0);
      transition: opacity 0.15s ease-out, transform 0.25s ease-out, border-radius 0.3s ease-in-out,
        visibility 0s;
      border-bottom-right-radius: 0;
    `}
`;

export const Input = styled.input`
  width: 100%;
  margin-bottom: 5px;
  border: none;
  background: #37474f;
  height: 80px;
  font-size: 1.3rem;
  letter-spacing: 0.2rem;
  padding: 0 15px;
  border-radius: 4px;
  color: #cfd8dc;
`;

export const ButtonsContainer = styled.div`
  width: 100%;
  height: 70%;
  display: flex;
  flex-direction: column;
  align-item: center;
  justify-content: center;
`;
export const ButtonInnerContainer = styled.div``;
export const Button = styled.button`
  display: inline-block;
  width: calc(33.33% - 10px);
  font-size: 14px;
  background: transparent;
  border: none;
  color: #cfd8dc;
  padding: 10px 0;
  text-align: center;
  cursor: pointer;
  font-size: 2rem;
  &:hover {
    color: #fff;
    // background-color: rgba(39, 50, 56, 0.8);
    background-color: rgba(0, 0, 0, 0.2);
  }

  &:nth-child(3n - 1) {
    margin: 0 15px;
  }
`;

export const CallButton = styled.button`
  margin-top: 5px;
  background: #8bc34a;
  color: #fff;
  width: 100%;
  height: 50px;
  border-radius: 20px;
  text-align: center;
  border: none;
  cursor: pointer;
  font-size: 1.3rem;
  &:hover {
    background: #7cb342;
  }
`;
export const EndButton = styled.div`
  margin-top: 5px;
  background: rgb(85%, 27%, 28%);
  color: #fff;
  width: 100%;
  height: 50px;
  border-radius: 20px;
  text-align: center;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background: rgba(85%, 27%, 28%, 0.9);
  }
`;

export const HoldCall = styled.div`
  color: white;
  cursor: pointer;
  border-radius: 10px;
  width: 4rem;
  display: flex;
  justify-content: center;
  padding: 10px;
  background: rgb(85%, 27%, 28%);
  font-size: 0.6rem;
  align-self: flex-end;
  &:hover {
    background: rgba(85%, 27%, 28%, 0.9);
  }
`;
export const ResumeCall = styled.div`
  color: white;
  cursor: pointer;
  border-radius: 10px;
  width: 5rem;
  display: flex;
  justify-content: center;
  padding: 10px;
  background: #0966c2;
  font-size: 0.6rem;
  align-self: flex-end;
  &:hover {
    background: rgb(9, 102, 194, 0.9);
  }
`;
export const MuteCall = styled.button`
  cursor: pointer;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-self: flex-start;
  // width: 3.5rem;
  background: transparent;
  border: none;
  outline: none;
  margin-left: 2rem;
  margin-bottom: 0.8rem;
  svg {
    align-self: center;
    font-size: 1.5rem;
  }
  .title {
    font-size: 0.7rem;
    text-align: center;
    margin-top: 4px;
  }
`;
