import React, { useState, useEffect } from 'react';
import { alpha, styled, useTheme } from '@mui/material/styles';
import { Card, Typography, Box, Button } from '@mui/material';
import { Icon } from '@iconify/react';
import { awsConnect, awsConnectInstanceId } from '../../../api/awsConnect';
import momentTZ from 'moment-timezone';
import moment from 'moment';
import { useSelector } from 'react-redux';
import {
  Timeline,
  TimelineItem,
  TimelineSeparator,
  TimelineContent,
  TimelineDot,
  TimelineConnector
} from '@mui/lab';
import Backend, { backendURL } from '../../../api/backend';
import _ from 'lodash';

const RootStyle = styled(Card)(({ theme }) => ({
    boxShadow: 'none',
    borderRadius:'16px',
  // textAlign: 'center',
  padding: theme.spacing(2, 2),
  color: theme.palette.common.black,
  // backgroundColor: theme.palette.error.lighter,
  backgroundColor: theme.palette.customBlackThemeColor.cardColor,
  height: '100%',
    width: '100%'
   
}));
const ActivityHeader = styled('div')(({ theme }) => ({
    display:'table',
    justifyContent: 'space-between',
    color: 'black',
    backgroundColor: 'white',
    padding: theme.spacing(1, 1),
    fontSize: '12px',
    width: '100%',
  '& .recentlyTime': {
      color: theme.palette.grey[600],
      fontSize:'12px'
  }
}));
const ChildrenStyles = styled('tr')(({ theme }) => ({
  border: '1px solid black',
  padding: theme.spacing(0, 0),
    height: '1.5rem',
    display: 'table-row',
    paddingTop: 1,
    alignItems: 'center',
   
}));
const TimeLineCustomItem = styled(TimelineItem)(({ theme }) => ({
  '&::before': {
    content: 'none',
    width: 0
  }
}));
const NoData = styled('p')(({ theme }) => ({
  textAlign: 'center',
    marginTop: '1rem',
    width: '100%',
  height:'100%',
  color: theme.palette.grey['500']
}));
const ActivityContent = styled('div')(({ theme }) => ({
    display: 'block',
    height: '400px',
    overflow:'auto',
  justifyContent: 'space-between',
  flexDirection: 'row',
  '& .voice': {
    color: theme.palette.grey[600],
    fontSize: '0.6rem'
  },
  '& .content-1': {
    display: 'flex',
      alignItem: 'center',
      fontSize: '12px',
    '.icon': {
      alignSelf: 'center'
    },
    '.phone': {
      display: 'flex',
      flexDirection: 'column',
        marginLeft: '4px'
      
    }
  },
  '& .content-2': {
    display: 'flex',
    flexDirection: 'column',
      marginLeft: '6px',
      fontSize: '12px'
    }
    ,
    '& .content-3': {
        display: 'flex',
        flexDirection: 'column',
        marginLeft: '6px',
        fontSize: '12px'
    },
    '.wrap-up': {
        display: 'flex',
        flexDirection: 'column',
        marginLeft: '4px',
        color:'#54D62C'

    }
}));
const ActivityFooter = styled('div')(({ theme }) => ({
  marginTop: '10px',
  '.title': {
    fontSize: '12px'
  },
  '.content': {
    color: theme.palette.grey['500']
  }
}));
const activityList = [
  {
    recentlyTime: '2 hour ago',
    type: 'OUTBOUND',
    number: '(619) 972-7326',
    name: 'TechinalSupport',
    time: '2019-04-25 10:25:22'
  },
  {
    recentlyTime: '3 hour ago',
    type: 'INBOUND',
    number: '(619) 972-7326',
    name: 'GeneralSupport',
    time: '2019-04-25 10:25:22'
  }
];


const AgentActivities = () => {
  const theme = useTheme();
  const data = useSelector((state) => state.recentlyActivity);
    const [recentlyActivityData, setRecentlyActivityData] = useState([]);
    const [q, setQ] = useState("");
    const [searchParam] = useState(["phoneNumber", "name", "channel", "callType","startTime","id","endTime"]);
  const getRecentActivities = async () => {
    try {
      const data = await Backend({
          method: 'GET',
          url: backendURL.getagentactivities
      });
      setRecentlyActivityData(_.sortBy(data.data.body.Items, ['timestamp']).reverse());
    } catch (error) {
      console.log('Getting error in fetching of recently activity');
      console.log(error);
      }

      //setTimeout(getRecentActivities, 10000);
    };

    function search(items) {
        return items.filter((item) => {
            return searchParam.some((newItem) => {
                return (
                    item[newItem] != null ?
                        item[newItem]
                            .toString()
                            .toLowerCase()
                            .indexOf(q.toLowerCase()) > -1
                        : ""
                );
            });
        });
    }
    useEffect(() => {
       
        let interval = setInterval(() => {
            getRecentActivities();
        }, 10000);
        return () => {
            clearInterval(interval);
        };
        
    }, []);
    return (
        <div >
            <ActivityFooter >
                <label htmlFor="search-form">
                    <input style={{ "padding":"5px","width":"350px","margin":"12px", "fontSize": "16px", "color": "black", "backgroundColor": "white" }}
                        type="search"
                        name="search-form"
                        id="search-form"
                        className="search-input"
                        placeholder="Search for..."
                        value={q}
                        /* 
                        // set the value of our useState e
                        //  anytime the user types in the search box
                        */
                        onChange={(e) => setQ(e.target.value)}
                    />
                   
                </label>
            </ActivityFooter >
       
        <ActivityContent>
            <ActivityHeader>
                
                <tbody>

                    <tr style={{ "height":"40px", "fontSize": "14px", 'fontWeight': "bold", "color":"red","backgroundColor":"lightgreen","borderStyle":"solid","borderColor":"black" }}>
                    <td>Agent Name</td>
                    <td>Phone Number</td>
                    <td>Start Time</td>
                    <td>End Time</td>
                    <td>Call Type</td>
                    <td>Channel Type</td>
                    <td>Call Id</td>
                    </tr>

                    {search([...recentlyActivityData]).map((item) => (
                        <ChildrenStyles key={item.id}>
                        <td>{item?.name}</td>
                        <td>{item?.phoneNumber}</td>
                        <td>{item?.startTime}</td>
                        <td>{item?.endTime}</td>
                        <td>{item?.callType}</td>
                        <td>{item?.channel}</td>
                         <td>{item?.id}</td>
                        </ChildrenStyles>
                ))}
                    </tbody>
            </ActivityHeader>
            </ActivityContent>
        </div>
    );


};

export default AgentActivities;
