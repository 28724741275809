import React from 'react';
import { alpha, styled } from '@mui/material/styles';
import { Box, Card, Typography, useTheme } from '@mui/material';
import './style.css';

function SimpleLoading({ percentage }) {
  React.useEffect(() => {
    const numb = document.querySelector('.number');
    // let counter = 0;
    // setInterval(() => {
    //   if (counter == 100) {
    //     clearInterval();
    //   } else {
    //     counter += 1;
    //     numb.innerHTML = counter + '%';
    //   }
    // }, 80);
  }, []);

  return (
    <div className="circular">
      <div className="inner"></div>
      <div className="dashboardProgressNumber">{percentage ? percentage : 0}</div>
      <div className="circle">
        <div className="bar left">
          <div className="progress"></div>
        </div>
        <div className="bar right">
          <div className="progress"></div>
        </div>
      </div>
    </div>
  );
}

export default SimpleLoading;
