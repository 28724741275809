import { useState } from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@iconify/react';
import { NavLink as RouterLink, matchPath, useLocation } from 'react-router-dom';
import arrowIosForwardFill from '@iconify/icons-eva/arrow-ios-forward-fill';
import arrowIosDownwardFill from '@iconify/icons-eva/arrow-ios-downward-fill';
// material
import { alpha, useTheme, styled } from '@mui/material/styles';
import { Box, List, Collapse, ListItemText, ListItemIcon, ListItemButton } from '@mui/material';

// ----------------------------------------------------------------------

const ListItemStyle = styled((props) => <ListItemButton disableGutters {...props} />)(
  ({ theme }) => ({
    ...theme.typography.body2,
    height: 80,
    position: 'relative',
    textTransform: 'capitalize',
    paddingLeft: theme.spacing(3.5),
    paddingRight: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    color: theme.palette.text.secondary,
    '&:before': {
      top: 0,
      right: 0,
      width: 3,
      bottom: 0,
      content: "''",
      display: 'none',
      position: 'absolute',
      borderTopLeftRadius: 4,
      borderBottomLeftRadius: 4,
      backgroundColor: theme.palette.primary.main
    }
  })
);

const ListItemIconStyle = styled(ListItemIcon)({
  width: 22,
  height: 22,
  display: 'flex',
  alignItems: 'center',
  paddingBottom: 20,
  paddingTop: 15,
  justifyContent: 'center'
});

const ListItemTextStyle = styled(ListItemText)({
  paddingRight: 13
});
const RefreshPage = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItem: 'center',
  color: theme.palette.text.secondary,
  '.MuiListItemIcon-root': {
    alignSelf: 'center'
  },
  textAlign: 'center',
  fontSize: '0.9rem',
  cursor: 'pointer',
  paddingTop: '10px',
  paddingBottom: '10px',
  '&:hover': {
    // backgroundColor: theme.palette.grey[900]
  }
}));
// ----------------------------------------------------------------------

NavItem.propTypes = {
  item: PropTypes.object,
  active: PropTypes.func
};

function NavItem({ item, active, mode }) {
  const theme = useTheme();
  const isActiveRoot = active(item.path);
  const { title, path, icon, info, children } = item;
  const [open, setOpen] = useState(isActiveRoot);

  const handleOpen = () => {
    setOpen((prev) => !prev);
  };

  const activeRootStyle = {
    color: 'primary.main',
    fontWeight: 'fontWeightMedium',
    bgcolor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
    '&:before': { display: 'block' }
  };

  const activeSubStyle = {
    color: 'text.primary',
    fontWeight: 'fontWeightMedium'
  };

  return (
    <ListItemStyle
      component={RouterLink}
      to={path}
      sx={{
        ...(isActiveRoot && activeRootStyle)
      }}
    >
      <ListItemIconStyle>{icon && icon}</ListItemIconStyle>
      <ListItemTextStyle disableTypography primary={title} />
      {info && info}
    </ListItemStyle>
  );
}

function NavItemBottom({ item, mode }) {
  const theme = useTheme();
  const { title, path, icon, info, children } = item;

  const handleOpen = () => {
    setOpen((prev) => !prev);
  };
  if (title === 'Refresh') {
    return (
      <RefreshPage
        onClick={() => {
          window.location.reload(true);
        }}
      >
        <ListItemIconStyle>{icon && icon}</ListItemIconStyle>
        <ListItemTextStyle disableTypography primary={title} />
        {info && info}
      </RefreshPage>
    );
  }
  return (
    <ListItemStyle component={RouterLink} to={path}>
      <ListItemIconStyle>{icon && icon}</ListItemIconStyle>
      <ListItemTextStyle disableTypography primary={title} />
      {info && info}
    </ListItemStyle>
  );
}

NavSection.propTypes = {
  navConfig: PropTypes.array
};

export default function NavSection({ mode, navConfig, navConfigBottom, ...other }) {
  const { pathname } = useLocation();
  const match = (path) => (path ? !!matchPath({ path, end: false }, pathname) : false);

  return (
    <Box {...other}>
      <List disablePadding>
        {navConfig.map((item) => (
          <NavItem key={item.title} item={item} mode={mode} active={match} />
        ))}
      </List>
      <List
        disablePadding
        sx={{
          marginTop: 18,
          position: 'absolute',
          bottom: '1rem',
          width: '100%'
        }}
      >
        {navConfigBottom.map((item) => (
          <NavItemBottom key={item.title} item={item} mode={mode} />
        ))}
      </List>
    </Box>
  );
}
