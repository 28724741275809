import { useEffect, useState } from 'react';
import { Icon } from '@iconify/react';
import bugFilled from '@iconify/icons-ant-design/bug-filled';
// material
import { alpha, styled } from '@mui/material/styles';
import { Box, Card, Typography, useTheme } from '@mui/material';
// utils
import { fShortenNumber } from '../../../utils/formatNumber';
import SimpleLoading from 'src/layouts/SImpleLoading/SimpleLoading';
import { awsConnect, awsConnectInstanceId } from '../../../api/awsConnect';
import { CircularProgressWithLabel } from './CircleProgressWithLabel';
// ----------------------------------------------------------------------


const RootStyle = styled(Card)(({ theme }) => ({
    boxShadow: 'none',
    //textAlign: 'center',
    padding: theme.spacing(2, 2),
    color: theme.palette.error.darker,
    backgroundColor: theme.palette.customBlackThemeColor.cardColor,
    height: '56%',
    width: '100%',
    overflow:'auto'
    
}));

const NoData = styled('p')(({ theme }) => ({
    textAlign: 'center',
    marginTop: '1.5rem',
    color: theme.palette.grey['500']
}));

const ChildrenStyles = styled(Box)(({ theme }) => ({
    boxShadow: 'none',
    padding: theme.spacing(2, 2),
    display: 'flex',
    flexDirection: 'row',
    paddingTop: 15,
    alignItems: 'center',
    justifyContent: 'space-around'
}));

const ChildItems = styled(Box)(({ theme }) => ({
    boxShadow: 'none',
    padding: theme.spacing(2, 2),
    display: 'flex',
    flexDirection: 'column',
    paddingTop: 30,
    alignItems: 'center',
    justifyContent: 'space-around'
}));
const Detail = styled('div')(({ theme }) => ({
    color: theme.palette.common.black,

    '.title': {
        fontSize: '1.1rem',
        fontWeight: 'bold'
    },
    '.content': {
        padding: '1rem',
        '.content-item': {
            display: 'flex',
            justifyContent: 'space-between',
            '.status': {
                color: theme.palette.grey[500]
            }
        }
    }
    
}));
// ----------------------------------------------------------------------

const TOTAL = 10;

export default function AgentAvailability({ totalAgents, agentAvailable, agentOnline }) {
    const theme = useTheme();
    const [agentStatusData, setAgentStatusData] = useState([]);
    
    const agentStatusList = () => {
        GetWallboard();
       

    };
   //var API_URI = "https://6z8v1wept5.execute-api.eu-central-1.amazonaws.com/prod/wallboard/?Wallboard=acdata";
    var API_URI = "https://ibvqxxm9u3.execute-api.eu-west-2.amazonaws.com/prod/wallboard/?Wallboard=admissnagentstatus";
    
   const RefreshInterval = 5000; // How often to retrieve data in milliseconds

    var API_Client = null;

    function GetWallboard() {

        API_Client = new XMLHttpRequest();
        API_Client.onreadystatechange = ProcessResponse;
        API_Client.open("get", API_URI);
        API_Client.setRequestHeader("Content-Type", "application/json");
        API_Client.timeout = 10000
        API_Client.ontimeout = ProcessTimeout;
        API_Client.send();

      setTimeout(GetWallboard, RefreshInterval);

    }
    function ProcessResponse() {
        if (API_Client.readyState == XMLHttpRequest.DONE) {
            try {
               var Result = API_Client.responseText;
                document.getElementById("wallboard").innerHTML = Result
            }
            catch (error) {
                console.log(error);
            }
        }
    }

    function ProcessTimeout() {
        console.log("Query to API Gateway timed out")
    }
    useEffect(() => {
        agentStatusList();
    }, []);
    return (
        <RootStyle>
            <Typography
                variant="subtitle2"
                sx={{
                    opacity: 0.72,
                    fontSize: 16,
                    //marginLeft:16,
                    // color: alpha(theme.palette.customBlackThemeColor.sidebarTextColor)
                    color: theme.palette.common.black
                }}
            >
                Live Agent Status
      </Typography>
            <Detail id="wallboard">
             <NoData>Loading data...</NoData>
            </Detail>

        </RootStyle>

    );
   
}