import axios from 'axios';
export const baseURL = 'https://spoycxvzbc.execute-api.eu-west-2.amazonaws.com/uob';

export const backendURL = {
  getWrapCode: 'getwrapcodes',
  addWrapCode: 'addwrapcodes',
    getRecentlyActivities: 'getrecentactivities',
    addRecentlyActivities: 'addrecentlyactivities',
    updateActivity: 'updateActivity',
    getagentperformance: 'getagentperformance',
    getagentactivities: 'getagentactivities',
};
const instance = axios.create({
  baseURL
});
export default instance;
