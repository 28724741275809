import React, { useState, useEffect } from 'react';
import { alpha, styled, useTheme } from '@mui/material/styles';
import { Card, Typography, Box, Grid } from '@mui/material';
import { Icon } from '@iconify/react';
import { awsConnect, awsConnectInstanceId } from '../../../api/awsConnect';
import { currentMetrics, historicalValues } from 'src/api/HistoricalMetrics';
const RootStyle = styled(Card)(({ theme }) => ({
    boxShadow: 'none',
    // textAlign: 'center',
    padding: theme.spacing(2, 2),
    color: theme.palette.common.black,
    backgroundColor: theme.palette.customBlackThemeColor.cardColor,
    height: '70%',
    width: '100%'
}));

const ChildrenStyles = styled(Box)(({ theme }) => ({
    padding: theme.spacing(0, 0)
}));
const ChildItem = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    '& .title': {
        fontSize: '1rem',
        fontWeight: 'bold'
    },
    '& .smallTitle': {
        fontSize: '1rem',
        fontWeight: 'bold',
        display: 'flex',
        justifyContent: 'space-between',
        alignItem: 'center',
        '& .sub': {
            fontSize: '0.5rem',
            color: theme.palette.grey[600]
        }
    }
}));
const ChildContainer = styled('span')(() => ({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around'
}));
const SmallBox = styled('div')(({ color }) => ({
    height: '.5rem',
    width: '.5rem',
    border: `1px solid ${color}`,
    backgroundColor: color,
    alignSelf: 'center',
    marginRight: '7px'
}));
const Contacts = () => {
    const theme = useTheme();
    const [totalContact, setTotalContact] = useState(0);
    const [contactInQueue, setContactInQueue] = useState(0);
    const [agentOnCall, setAgentOnCall] = useState(0);
    const [agentAvailable, setAgentAvailable] = useState(0);
    const [agentOnContact, setagentOnContact] = useState(0);
    const [agentACW, setagentACW] = useState(0);
    const [agentError, setagentError] = useState(0);
    const [agentOnline, setAgentOnline] = useState(0);
    const [metricsData, setMetricsData] = React.useState(null);
    useEffect(() => {
        let currentMetricsInterval = setInterval(() => {
            // var agent = new connect.Agent();
            //var ARNs = agent.getAllQueueARNs();
            awsConnect.getCurrentMetricData(
                {
                    InstanceId: awsConnectInstanceId,
                    CurrentMetrics: currentMetrics,
                    Filters: {
                        Channels: ['VOICE'], //[('CHAT', 'TASK')],
                        Queues: [
                            'arn:aws:connect:eu-west-2:767282783672:instance/aed7cc34-ef66-4748-a407-4993ac449ccc/queue/13577535-ef22-4535-be2f-1578d4c74016'
                        ]
                    },
                    Groupings: ['QUEUE', 'CHANNEL']
                    // MaxResults: 20
                },
                (err, data) => {
                    if (data) {
                        setMetricsData(data);
                        if (data.MetricResults.length > 0) {
                            data.MetricResults.map((item) => {
                                item.Collections.map((colItem) => {
                                    if (colItem.Metric.Name === 'CONTACTS_IN_QUEUE') {
                                        setContactInQueue(colItem.Value);
                                    } else if (colItem.Metric.Name === 'AGENTS_AVAILABLE') {
                                        setAgentAvailable(colItem.Value);
                                    } else if (colItem.Metric.Name === 'AGENTS_ONLINE') {
                                        setAgentOnline(colItem.Value);
                                    } else if (colItem.Metric.Name === 'AGENTS_ON_CALL') {
                                        setAgentOnCall(colItem.Value);
                                    }
                                    else if (colItem.Metric.Name === 'AGENTS_ON_CONTACT') {
                                        setagentOnContact(colItem.Value);
                                    }
                                    else if (colItem.Metric.Name === 'AGENTS_AFTER_CONTACT_WORK') {
                                        setagentACW(colItem.Value);
                                    }
                                    else if (colItem.Metric.Name === 'AGENTS_ERROR') {
                                        setagentError(colItem.Value);
                                    }
                                });
                            });
                        }
                    } else if (err) {
                        console.log('Error found in getting data of aws connect');
                        console.log(err);
                        console.log(err.stack);
                    }
                }
            );
        }, 10000);
        return () => {
            clearInterval(currentMetricsInterval);
        };
    }, []);
    return (
        <RootStyle>
            <Typography
                variant="subtitle2"
                sx={{
                    opacity: 0.72,
                    fontSize: 16,
                    color: alpha(theme.palette.customBlackThemeColor.sidebarTextColor)
                }}
            >
                Live Data
      </Typography>
            <ChildrenStyles>
                <Grid container direction="column" spacing={5}>
                    <Grid item>
                        <ChildItem>
                            <span className="title">{contactInQueue}</span>
                            <span className="smallTitle">contacts in queue</span>
                        </ChildItem>
                    </Grid>
                    <Grid item>
                        <ChildContainer>
                            <ChildItem>
                                <span className="smallTitle">
                                    <SmallBox color={theme.palette.customBlackThemeColor.sidebarTextColor} />

                                    {agentAvailable}

                                </span>
                                <span className="smallTitle">Available</span>
                            </ChildItem>
                            <ChildItem>
                                <span className="smallTitle">
                                    <SmallBox color={theme.palette.customBlackThemeColor.sidebarTextColor} />{agentOnline}
                                </span>
                                <span className="smallTitle">Online</span>
                            </ChildItem>
                            <ChildItem>
                                <span className="smallTitle">
                                    <SmallBox color={theme.palette.customBlackThemeColor.sidebarTextColor} />
                                    {agentOnCall}
                                </span>
                                <span className="smallTitle">On Call</span>
                            </ChildItem>
                        </ChildContainer>
                    </Grid>
                    <Grid item>
                        <ChildContainer>
                            <ChildItem>
                                <span className="smallTitle">
                                    <SmallBox color={theme.palette.chart.green[0]} />{agentOnContact}
                                </span>
                                <span className="smallTitle">On Contact</span>
                            </ChildItem>
                            <ChildItem>
                                <span className="smallTitle">
                                    <SmallBox color={theme.palette.chart.yellow[0]} />

                                    {agentACW}
                                </span>
                                <span className="smallTitle">ACW</span>
                            </ChildItem>
                            <ChildItem>
                                <span className="smallTitle">
                                    <SmallBox color="pink" />{agentError}
                                </span>
                                <span className="smallTitle">Error</span>
                            </ChildItem>
                        </ChildContainer>
                    </Grid>
                </Grid>
            </ChildrenStyles>
        </RootStyle>
    );
};

export default Contacts;
